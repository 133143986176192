import React, { useState } from "react";
import Logo from "../../assets/images/CHARGELOGO.png";
import { useMedia } from "../../hook/useMedia";
import clsx from "clsx";
import Footer from "../common/Footer/Footer";
import PreferencePageWrapper from "./Preference.style";
import { PREFERENCE_TYPE } from "../../helper/constants";
import { StyledButton } from "../common/StyledButton";
import { ArrowLeft } from "../../icons";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";

const Preference = (props) => {
  const { isTabletOrMobile, isMobile, isAfterTablet } = useMedia();
  const { t } = useTranslation();

  const [preferred, setPreferred] = useState(null);

  const handleNext = () => {
    if (preferred === PREFERENCE_TYPE.ALL)
      props.history.push("/rd-list", {
        type: preferred,
      });
    else {
      props.history.push("/rd-slots", {
        type: preferred,
        is_rd_available: true,
      });
    }
  };

  return (
    <PreferencePageWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "relative",
        }}
      >
        <div className="pos-absolute" style={{ right: 12, top: 4 }}>
          <LanguageSelector />
        </div>
        <div className="flex-100 pt-18">
          <div>
            <img src={Logo} height={"100%"} width="100%" alt={"Husk Logo"} />
          </div>
        </div>
        <div className="d-flex flex-y flex-1">
          <div
            className={clsx(
              "Glacial-Bold jumbotrons fs-24 pa-12",
              !isTabletOrMobile && "fs-34 pa-32"
            )}
          >
            {t("PREFERENCE_HEADING_LABEL")}
          </div>

          <div
            className={clsx(
              "flex flex-1 ptb-20",
              !isTabletOrMobile && "plr-72 ptb-32",
              isTabletOrMobile && "flex-y plr-42"
            )}
          >
            <div
              role="button"
              onClick={() => setPreferred(PREFERENCE_TYPE.BY_TIME)}
              className={clsx(
                "flex flex-1 text-center font-bold justify-content-center align-items-center fs-20 pa-16  border-radius-20 preference-card",
                !isAfterTablet && "pa-46 fs-32",
                preferred === PREFERENCE_TYPE.BY_TIME &&
                  "selected-preference-card"
              )}
              style={{ maxHeight: isMobile && 200, minHeight: isMobile && 200 }}
            >
              {t("SELECT_RD_BY_TIME_OPTION_LABEL")}
            </div>
            <div
              className={clsx(
                "flex justify-content-center font-bold align-items-center pa-16 fs-20",
                !isAfterTablet && "fs-32 pa-32"
              )}
            >
              {t("OR_LABEL")}
            </div>
            <div
              role="button"
              onClick={() => setPreferred(PREFERENCE_TYPE.ALL)}
              className={clsx(
                "flex flex-1 text-center font-bold justify-content-center align-items-center fs-20 pa-16  border-radius-20 preference-card",
                !isAfterTablet && "pa-46 fs-32",
                preferred === PREFERENCE_TYPE.ALL && "selected-preference-card"
              )}
              style={{ maxHeight: isMobile && 200, minHeight: isMobile && 200 }}
            >
              {t("BROWSE_ALL_RD_OPTION_LABEL")}
            </div>
          </div>
          <div>
            <div style={{ float: "right" }}>
              <StyledButton
                py="8"
                px="12"
                mr="60"
                mt="50"
                mb="60"
                borderRadius="8px"
                color="#fff"
                bgColor="#AECE38"
                hoverColor="#AECE38"
                border={"1px solid #AECE38"}
                width="160px"
                className="font-bold glacial"
                disabled={preferred ? false : true}
                onClick={handleNext}
                iconRight={ArrowLeft}
              >
                {t("NEXT_BUTTON")}
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
      <Footer width={100}></Footer>
    </PreferencePageWrapper>
  );
};

export default Preference;
