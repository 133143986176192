import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import TNCImg from "../../assets/images/tnc.png";
import { Switch, message } from "antd";
import Button from "../../components/common/Button/Button";
import { connect } from "react-redux";
import AuthServices from "../../api/AuthServices";
import { PREFERENCE_TYPE } from "../../helper/constants";

const TncModal = ({ open, toggle, data, ...props }) => {
  const { t, i18n } = useTranslation();
  const [isAck, setIsAck] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    AuthServices.acknowledgement({ user_id: props?.userId }, i18n?.language)
      .then((res) => {
        if (res?.status) {
          if (data?.user_rd_service.admin_id) {
            props.history.push("/rd-slots", {
              ...data?.user_rd_service?.admin,
              type: PREFERENCE_TYPE.ALL,
              providerCode: true,
            });
          } else if (data?.is_rd_available) {
            props.history.push("/preference", data);
          } else {
            props.history.push("/discovery-queue");
          }
        } else {
          message.error("Something went wrong! Please try again.");
        }
        setLoading(false);
        toggle();
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        setLoading(false);
        toggle();
      });
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} centered backdrop="static">
        <ModalBody>
          <div className="flex flex-column justify-content-center align-center Glacial-Regular plr-12 fill-width">
            <div className="mtb-20 fs-20 headline">
              {t("TERMS_AND_CONDITION")}
            </div>
            <div className="ptb-10">
              <img src={TNCImg} height={200} width={232} />
            </div>
            <div className="text-center mt-10 plr-20">
              {t("TNC_MODAL_AGREE")}{" "}
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(
                    `${process.env.REACT_APP_PUBLIC_URL}/terms`,
                    "_blank"
                  )
                }
              >
                {t("TERMS_AND_CONDITION")}
              </span>
              ,
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(
                    `${process.env.REACT_APP_PUBLIC_URL}/privacy`,
                    "_blank"
                  )
                }
              >
                {" "}
                {t("PRIVACY_POLICY")}
              </span>
              ,
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(
                    `${process.env.REACT_APP_PUBLIC_URL}/hipaa-consent`
                  )
                }
              >
                {" "}
                {t("HIPPA_POLICY")}
              </span>{" "}
              {t("AND")}{" "}
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(`${process.env.REACT_APP_PUBLIC_URL}/payment`)
                }
              >
                {" Husk "}
                {t("PAYMENT_POLICY")}
              </span>
              .
            </div>
            <div className="flex flex-x justify-content-center pt-20 font-bold">
              <span>
                {t("TNC_MODAL_READ_UNDERSTOOD")} {t("TERMS_AND_CONDITION")},{" "}
                {t("PRIVACY_POLICY")}, {t("HIPPA_POLICY")} {t("AND")} Husk{" "}
                {t("PAYMENT_POLICY")}.
              </span>
              <div className="flex justify-content-center align-center">
                <Switch
                  checked={isAck}
                  onChange={() => {
                    setIsAck((prev) => !prev);
                  }}
                />
              </div>
            </div>
            <div className="fill-width">
              <Button
                className="text-center"
                disabled={!isAck}
                width="100%"
                text={t("SUBMIT_BUTTON")}
                onClick={handleSubmit}
                loading={loading}
              ></Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

export default connect(mapStateToProps)(TncModal);
