import React from "react";
import CalendlyWrapper from "./Calendly.style";
import { withRouter } from "react-router-dom";
const Calendly = (props) => {
  let calendly_link = props.location.state;
  return (
    <CalendlyWrapper>
      <div style={{ height: "100vh" }}>
        <iframe
          src={calendly_link}
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
      </div>
    </CalendlyWrapper>
  );
};

export default withRouter(Calendly);
