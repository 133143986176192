import { useMediaQuery } from "react-responsive";

export const useMedia = () => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1124 });
  const isAfterDesktop = useMediaQuery({ maxWidth: 1124 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 820 });
  const isAfterTablet = useMediaQuery({ maxWidth: 720 });
  const isMobile = useMediaQuery({ maxWidth: 420 });
  const isMobileForGrid = useMediaQuery({ maxWidth: 580 });
  const isLaptop = useMediaQuery({ maxWidth: 1380 });
  const isTabletTilt = useMediaQuery({ maxWidth: 1180 });
  const isLg = useMediaQuery({ minWidth: 1024 });
  const isMd = useMediaQuery({ minWidth: 768 });
  const isTbLg = useMediaQuery({ minWidth: 1024, maxWidth: 1380 });

  return {
    isBigScreen,
    isDesktopOrLaptop,
    isTabletOrMobile,
    isMobile,
    isMobileForGrid,
    isAfterTablet,
    isLaptop,
    isAfterDesktop,
    isTabletTilt,
    isLg,
    isMd,
    isTbLg,
  };
};
