import React from "react";
import SlotsPageWrapper from "./Slots.style";
import Logo from "../../assets/images/CHARGELOGO.png";
import { useMedia } from "../../hook/useMedia";
import clsx from "clsx";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import Footer from "../common/Footer/Footer";
import { RDCard } from "./RDCard";
import { INIT_FILTERS, PREFERENCE_TYPE } from "../../helper/constants";
import { ArrowLeft, FilterIcon, Loader } from "../../icons";
import RDFilter from "./Filter";
import AuthenticationApiSerivce from "../../api/AuthServices";
import { StyledButton } from "../common/StyledButton";
import { lastDayOfWeek, startOfWeek, subDays } from "date-fns";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";

const RDList = (props) => {
  const { isTabletOrMobile } = useMedia();
  const { t } = useTranslation();
  const { state } = props.location;

  const [rdList, setRdList] = useState([]);
  const [selectedSpecialies, setSelectedSpecialies] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedTz, setSelectedTz] = useState(null);
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [transitionExit, setTransitionExit] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [tzOptions, setTzOptions] = useState([]);
  const [opLoading, setOpLoading] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  useEffect(() => {
    if (state?.type === PREFERENCE_TYPE.ALL) {
      setOpLoading(true);
      AuthenticationApiSerivce.commonFilterRDList()
        .then((res) => {
          setSpecialityOptions([...res.data.category_list]);
          setLanguageOptions([...res.data.language_list]);
          setTzOptions([...res.data.timezone_list]);
          setOpLoading(false);
        })
        .catch((err) => {
          setOpLoading(false);
          message.error(`Filter Lists: ${err.message}`);
        });
    }
  }, [state?.type]);

  useEffect(() => {
    if (!state) {
      props.history.push("/preference");
    } else {
      if (state?.type === PREFERENCE_TYPE.ALL) {
        setLoading(true);
        const params = {
          timezone_id: filters?.timezone ? String(filters?.timezone) : "",
          language_id: filters?.language?.length
            ? String(filters?.language)
            : "",
          category_id: filters?.speciality?.length
            ? String(filters?.speciality)
            : "",
          start_date: moment().format("YYYY-MM-DD"),
          // moment(
          //   startOfWeek(currentMonth, { weekStartsOn: 1 })
          // ).format("YYYY-MM-DD"),
          end_date: moment().add(6, "days").format("YYYY-MM-DD"),
          // moment(
          //   subDays(lastDayOfWeek(currentMonth, { weekStartsOn: 1 }), 2)
          // ).format("YYYY-MM-DD"),
        };

        AuthenticationApiSerivce.matchedRDList(params)
          .then((res) => {
            setRdList(res.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            message.error("Something went wrong! Please try again.");
          });
      } else {
        setRdList(state.rd_info);
      }
    }
  }, [state, filters]);

  const handleBack = () => {
    props.history.push("/rd-slots", {
      type: PREFERENCE_TYPE.BY_TIME,
      is_rd_available: true,
    });
  };

  const handleBook = (rd) => {
    props.history.push(
      state?.type === PREFERENCE_TYPE.BY_TIME
        ? "/schedule-appointment"
        : "/rd-slots",
      { ...rd, type: state?.type }
    );
  };

  const handleFilter = () => {
    setTransitionExit(true);

    setSelectedLanguages(filters.language);
    setSelectedSpecialies(filters.speciality);
    setSelectedTz(filters.timezone);

    setTimeout(() => {
      setIsFilterOpen((prev) => !prev);
      setTransitionExit(false);
    }, 100);
  };

  const addToDiscoveryQueue = () => {
    props?.history.push("/discovery-queue");
  };

  return (
    <SlotsPageWrapper>
      <div
        className={clsx(
          "flex flex-1",
          state?.type !== PREFERENCE_TYPE.ALL
            ? "flex-y overflow-auto design-scrollbar"
            : "flex-x"
        )}
      >
        {state?.type !== PREFERENCE_TYPE.ALL ? (
          <>
            <div className="flex-100 pt-18 pos-relative">
              <div className="pos-absolute" style={{ right: 12, top: 4 }}>
                <LanguageSelector />
              </div>
              <div>
                <img
                  src={Logo}
                  height={"100%"}
                  width="100%"
                  alt={"Husk Logo"}
                />
              </div>
            </div>
            <div className="d-flex flex-y ">
              <div
                className={clsx(
                  "Glacial-Bold jumbotrons fs-24 pa-12 text-center",
                  !isTabletOrMobile && "fs-34 plr-20 pt-20"
                )}
              >
                {t("RD_LIST_BY_SLOT_LABEL")}{" "}
                <span className="color-green">
                  {moment
                    .tz(state?.start_time, moment.tz.guess())
                    .format("dddd, MMMM Do, YYYY")}{" "}
                  at{" "}
                  {moment
                    .tz(state?.start_time, moment.tz.guess())
                    .format("hh:mm A")}
                </span>
              </div>

              <div
                className={clsx(
                  "color-dark fs-16 plr-12 pb-12 text-center",
                  !isTabletOrMobile && "fs-20 plr-10 pb-10"
                )}
              >
                {t("SELECT_ONE_RD_TEXT_LABEL_ONE")}{" "}
                <a
                  className="underline color-dark color-dark-underline"
                  onClick={handleBack}
                >
                  {t("GO_BACK_BUTTON")}
                </a>{" "}
                {t("SELECT_ONE_RD_TEXT_LABEL_TWO")}
              </div>

              <div className={clsx("flex flex-y flex-1 plr-32 pb-40")}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: isTabletOrMobile
                      ? "repeat(1, minmax(0, 1fr))"
                      : "repeat(2, minmax(0, 1fr))",
                    width: "100%",
                  }}
                >
                  {rdList.map((rd, index) => (
                    <RDCard
                      rd={rd}
                      key={rd?.id}
                      length={rdList.length}
                      index={index}
                      handleBook={() => handleBook(rd)}
                      type={PREFERENCE_TYPE.BY_TIME}
                    />
                  ))}
                </div>
                <div
                  className={clsx(
                    "fs-16 text-center mtb-12 color-dark",
                    !isTabletOrMobile && "fs-20"
                  )}
                >
                  {t("PREFER_BROWSE_ALL_LABEL")}{" "}
                  <span
                    className="underlineText cursor-pointer"
                    onClick={() =>
                      props.history.push("/rd-list", {
                        type: PREFERENCE_TYPE.ALL,
                      })
                    }
                  >
                    {t("VIEW_ALL_RD_BUTTON")}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {isFilterOpen && isTabletOrMobile && (
              <div
                className={`drawer_container ${transitionExit ? "exit" : ""}`}
              >
                <div className={`drawer ${transitionExit ? "exit" : ""}`}>
                  <RDFilter
                    setIsFilterChanged={setIsFilterChanged}
                    isFilterChanged={isFilterChanged}
                    specialityOptions={specialityOptions}
                    languageOptions={languageOptions}
                    tzOptions={tzOptions}
                    opLoading={opLoading}
                    selectedLanguages={selectedLanguages}
                    setSelectedLanguages={setSelectedLanguages}
                    selectedSpecialies={selectedSpecialies}
                    setSelectedSpecialies={setSelectedSpecialies}
                    selectedTz={selectedTz}
                    setSelectedTz={setSelectedTz}
                    filters={filters}
                    setFilters={setFilters}
                    handleFilter={handleFilter}
                    setIsFilterOpen={setIsFilterOpen}
                  />
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <RDFilter
                setIsFilterChanged={setIsFilterChanged}
                isFilterChanged={isFilterChanged}
                specialityOptions={specialityOptions}
                languageOptions={languageOptions}
                tzOptions={tzOptions}
                opLoading={opLoading}
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
                selectedSpecialies={selectedSpecialies}
                setSelectedSpecialies={setSelectedSpecialies}
                selectedTz={selectedTz}
                setSelectedTz={setSelectedTz}
                filters={filters}
                setFilters={setFilters}
                handleFilter={handleFilter}
                setIsFilterOpen={setIsFilterOpen}
              />
            )}
            <div className="flex flex-1 flex-y  full-height justify-content-between">
              <div className="flex flex-y ptb-8  flex-1 overflow-auto design-scrollbar">
                <div
                  className={clsx(
                    "flex flex-x align-items-center ",
                    !isTabletOrMobile
                      ? "justify-content-end plr-32"
                      : "justify-content-between"
                  )}
                >
                  {isTabletOrMobile && (
                    <div className="plr-32 cursor-pointer pos-relative">
                      <FilterIcon
                        height={28}
                        width={28}
                        fill="#5A6B80"
                        className="rotate-90"
                        onClick={handleFilter}
                      />
                      {(!!filters.speciality.length ||
                        !!filters.language.length ||
                        !!filters.timezone) && (
                        <div className="pos-absolute h-12 w-12 border-radius-6 bg-orange bottom-62 right-24" />
                      )}
                    </div>
                  )}
                  <div>
                    <img
                      src={Logo}
                      height={"100%"}
                      width="100%"
                      alt={"Husk Logo"}
                    />
                  </div>
                </div>
                {!!loading && (
                  <div className="flex flex-1 align-items-center justify-center fs-18 light-blue ptb-20">
                    <Loader height="72px" width="72px"></Loader>
                  </div>
                )}
                {!!rdList?.length && !loading ? (
                  <>
                    <div className="flex flex-x justify-content-between align-items-center plr-32">
                      <div className="flex-y">
                        <div className="color-orange font-bold fs-24">
                          {`${t("RESULTS")} ${rdList?.length} ${
                            rdList.length === 1 ? t("RD") : t("RDS")
                          } ${rdList.length === 1 ? t("MATCHES") : t("MATCH")}
                          ${t("MATCH_SEARCH_CRITERIA")}`}
                        </div>
                        <div className="color-dark">
                          {t("NOT_WHAT_LOOKING_FOR")}{" "}
                          <span
                            className="underlineText cursor-pointer"
                            onClick={() => props.history.push("/preference")}
                          >
                            {t("GO_BACK_CAP_BUTTON")}
                          </span>{" "}
                          {t("SELECT_TIME_GO_BACK")}
                        </div>
                      </div>
                    </div>
                    <div
                      className="pb-6 plr-32 fill-width"
                      style={{
                        display: "grid",
                        gridTemplateColumns: isTabletOrMobile
                          ? "repeat(1, minmax(0, 1fr))"
                          : "repeat(2, minmax(0, 1fr))",
                      }}
                    >
                      {rdList.map((rd, index) => (
                        <RDCard
                          rd={rd}
                          key={rd?.id}
                          length={rdList.length}
                          index={index}
                          handleBook={() => handleBook(rd)}
                          type={PREFERENCE_TYPE.ALL}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  !loading && (
                    <div
                      className={clsx(
                        "flex flex-1 flex-y text-center justify-content-center align-items-center font-normal fs-18 pa-16",
                        !isTabletOrMobile && "fs-24 pa-48"
                      )}
                    >
                      <div className="ptb-20 color-dark">
                        {t("NO_RD_MATCHED_TEXT")}
                      </div>
                      <StyledButton
                        py="8"
                        px={"20"}
                        borderRadius="12px"
                        color="#fff"
                        bgColor="#AECE38"
                        hoverColor="#AECE38"
                        border={"1px solid #AECE38"}
                        className="font-bold"
                        onClick={addToDiscoveryQueue}
                      >
                        {t("SCHEDULE_DISCOVERY_CALL_BUTTON")}
                      </StyledButton>
                      <StyledButton
                        py={isTabletOrMobile ? "6" : "8"}
                        px={isTabletOrMobile ? "8" : "12"}
                        mt="10"
                        mb="60"
                        borderRadius="12px"
                        color="#fff"
                        bgColor="#AECE38"
                        hoverColor="#AECE38"
                        border={"1px solid #AECE38"}
                        width={isTabletOrMobile ? "120px" : "160px"}
                        className="font-bold glacial"
                        onClick={() => props.history.push("/preference")}
                      >
                        {t("BACK_BUTTON")}
                      </StyledButton>
                      <StyledButton
                        py={isTabletOrMobile ? "6" : "8"}
                        px={isTabletOrMobile ? "8" : "12"}
                        mt="10"
                        mb="60"
                        borderRadius="12px"
                        color="#fff"
                        bgColor="#AECE38"
                        hoverColor="#AECE38"
                        border={"1px solid #AECE38"}
                        width={isTabletOrMobile ? "120px" : "160px"}
                        className="font-bold glacial"
                        onClick={() => props.history.push("/preference")}
                      >
                        Back
                      </StyledButton>
                    </div>
                  )
                )}
              </div>
              <div className="pa-8 bg-dark flex justify-content-center align-items-center color-white">
                <div className="Monts-Medium fs-14 color-white">
                  {t("FOOTER_TEXT")}{" "}
                  <a
                    href="mailto:support@huskwellness.com"
                    className="color-white underline"
                  >
                    support@huskwellness.com
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {/* // )} */}
      </div>
      {state?.type !== PREFERENCE_TYPE.ALL && <Footer width={100}></Footer>}
    </SlotsPageWrapper>
  );
};

export default RDList;
