import React from "react";
import SlotsPageWrapper from "./Slots.style";
import Logo from "../../assets/images/CHARGELOGO.png";
import { useMedia } from "../../hook/useMedia";
import clsx from "clsx";
import GooglePlayImage from "../../assets/images/GooglePlayBadge.png";
import AppStoreImage from "../../assets/images/AppStoreBadge.png";
import { appleUrl, googleUrl } from "../../helper/constants";
import Footer from "../common/Footer/Footer";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";

const steps = [
  {
    name: "DOWNLOAD_APP_TEXT",
    desc: "DOWNLOAD_APP_SUB_TEXT",
  },
  {
    name: "CREATE_PASSWORD_TEXT",
    desc: "CREATE_PASSWORD_SUB_TEXT",
  },
  {
    name: "COMPLETE_PROFILE_TEXT",
    desc: "COMPLETE_PROFILE_SUB_TEXT",
  },
];

const AppointmentConfirmation = (props) => {
  const { isTabletOrMobile, isDesktopOrLaptop, isMobile } = useMedia();
  const { t } = useTranslation();

  return (
    <SlotsPageWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "relative",
        }}
      >
        <div className="pos-absolute" style={{ right: 12, top: 4 }}>
          <LanguageSelector />
        </div>
        <div className="flex-100 pt-18">
          <div>
            <img src={Logo} height={"100%"} width="100%" alt={"Husk Logo"} />
          </div>
        </div>
        <div className="d-flex flex-y ">
          <div
            className={clsx(
              "Glacial-Bold jumbotrons fs-24 pa-12 text-center",
              !isTabletOrMobile && "fs-34 pa-32"
            )}
          >
            {t("DOWNLOAD_HUSK_NUTRITION_TEXT")}
          </div>
          <div className="app-icon pb-6 text-center">
            <img
              className="cursor-pointer"
              src={GooglePlayImage}
              alt="Play store icon"
              onClick={(e) => {
                window.open(googleUrl);
              }}
            ></img>
            <img
              className="mlp-3 cursor-pointer"
              src={AppStoreImage}
              alt="App store icon"
              onClick={(e) => {
                window.open(appleUrl);
              }}
            ></img>
          </div>
          <div className="flex-100 ptb-24">
            <div className="flex flex-1">
              <div className="flex flex-1" />
              <div className="flex">
                <div
                  className={clsx(
                    "h-28 w-28 border-round border-green bg-light-green",
                    !isMobile && "h-32 w-32"
                  )}
                />
              </div>
              <div className="flex flex-1">
                <div className="dashed-border" />
              </div>
            </div>
            <div className="flex flex-1">
              <div className="flex flex-1">
                <div className="dashed-border pr-1" />
              </div>
              <div
                className={clsx(
                  "h-28 w-28 border-round border-green bg-light-green",
                  !isMobile && "h-32 w-32"
                )}
              />
              <div className="flex flex-1">
                <div className="dashed-border" />
              </div>
            </div>
            <div className="flex flex-1">
              <div className="flex flex-1">
                <div className="dashed-border" />
              </div>
              <div
                className={clsx(
                  "h-28 w-28 border-round border-green bg-light-green",
                  !isMobile && "h-32 w-32"
                )}
              />
              <div className="flex flex-1" />
            </div>
          </div>

          <div className="flex-100 ptb-6">
            {steps.map((el) => (
              <div className="flex flex-y plr-16 flex-1 ">
                <div
                  className={clsx(
                    "text-center font-bold color-green fs-20",
                    !isMobile && "fs-26"
                  )}
                >
                  {t(el.name)}
                </div>
                <div
                  className={clsx(
                    "text-center color-dark fs-14",
                    !isMobile && "fs-18"
                  )}
                >
                  {t(el.desc)}
                </div>
              </div>
            ))}
          </div>

          {/* <div className="flex-100 plr-32 mtb-24 justify-center align-center">
            <div
              className={clsx(
                "flex cursor-pointer justify-center align-center bg-green w-280 color-white border-radius-8 ptb-8  fs-16 font-bold",
                isMobile && "w-100"
              )}
              onClick={handleBookNow}
            >
              Book Appointment
            </div>
          </div> */}
        </div>
      </div>
      <Footer width={100}></Footer>
    </SlotsPageWrapper>
  );
};

export default AppointmentConfirmation;
