import axios from "axios";
import { Url } from "../helper/constants";
import Header from "./Header";
import Encryption from "./Encryption";
export default class AuthServices {
  // Check email
  static checkEmail(params) {
    return axios
      .post(`${Url}check-email`, Encryption.encrypt(params))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  // get registration code
  static getRegistrationCode() {
    return axios.get(`${Url}get-registration-code`).then((res) => {
      return Encryption.decrypt(res.data);
    });
  }

  // CheckEmailAndCode is valid or not
  static checkEmailAndCode(params) {
    return axios
      .post(`${Url}check-email-and-code`, Encryption.encrypt(params))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  // Insurence detail

  static insurenceList(params) {
    return axios
      .get(
        `${Url}common/insurance-list?type=register&registration_code=${params}`
      )
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  // Signup with all detail
  static signup(params, lng) {
    Header.setLangHeaders(lng);
    return axios.post(`${Url}signup`, params).then((res) => {
      return Encryption.decrypt(res.data);
    });
  }

  // Register user get stream
  static registerUserGetStream(params) {
    Header.setHeaders();
    return axios.get(`${Url}register-user-getstream`).then((res) => {
      return Encryption.decrypt(res.data);
    });
  }

  // send message from RD
  static sendMessageFromRD(params) {
    Header.setHeaders();
    return axios.get(`${Url}common/send-message`).then((res) => {
      return Encryption.decrypt(res.data);
    });
  }

  //static pages
  static staticPages(params) {
    return axios
      .post(`${Url}common/static-page`, Encryption.encrypt(params))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  // RD Slot List
  static rdSlotList(params) {
    Header.setHeaders();
    return axios
      .post(`${Url}list-rd-timeslot`, Encryption.encrypt(params))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  //Matched RD List
  static matchedRDList(params) {
    Header.setHeaders();
    Header.setTzHeader();

    return axios
      .post(`${Url}list-matched-rd`, Encryption.encrypt(params))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  //RD Slot List By ID
  static rdSlotListById(params, cT) {
    Header.setHeaders();
    return axios
      .post(`${Url}list-timeslot-by-rd`, Encryption.encrypt(params), cT)
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  //filter-rd-common-list
  static commonFilterRDList(params) {
    Header.setHeaders();
    return axios
      .get(`${Url}common/filter-rd-common-list`, Encryption.encrypt(params))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  // Get Languages
  static getLanguages() {
    Header.setHeaders();
    return axios.get(`${Url}language/list`).then((res) => {
      return Encryption.decrypt(res.data);
    });
  }

  //Location List By RD ID
  static rdLocationListByRdId(params) {
    Header.setHeaders();
    return axios
      .post(`${Url}list-rd-appointment-location`, Encryption.encrypt(params))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  static changeLanguage(params) {
    return axios.post(
      `${Url}language/web-language-object`,
      Encryption.encrypt(params)
    );
  }

  // acknowlegment
  static acknowledgement(params, lng) {
    Header.setHeaders();
    Header.setLangHeaders(lng);
    return axios
      .get(`${Url}update-acknowledgement`, Encryption.encrypt(params, 1))
      .then((res) => {
        return Encryption.decrypt(res.data);
      });
  }

  //discovery queue
  static addToDiscoveryQueue() {
    Header.setHeaders();
    return axios.get(`${Url}add-client-to-discovery-queue`).then((res) => {
      return Encryption.decrypt(res.data);
    });
  }
}
