import React, { useMemo, useState } from "react";
import { CloseIcon, GreaterIcon, Loader } from "../../icons";
import clsx from "clsx";
import { useMedia } from "../../hook/useMedia";
import FilterWrapper from "./Filter.style";
import { StyledButton } from "../common/StyledButton";
import { INIT_FILTERS } from "../../helper/constants";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const RDFilter = ({
  handleFilter,
  selectedLanguages,
  setSelectedLanguages,
  selectedSpecialies,
  setSelectedSpecialies,
  selectedTz,
  setSelectedTz,
  filters,
  setFilters,
  setIsFilterOpen,
  languageOptions,
  tzOptions,
  specialityOptions,
  opLoading,
  setIsFilterChanged,
  isFilterChanged,
  ...props
}) => {
  const { state, insurance } = props;
  const { isTabletOrMobile, isMobile } = useMedia();
  const { t } = useTranslation();

  const [isSpecialityOpen, setIsSpecialityOpen] = useState(
    !!filters?.speciality?.length
  );
  const [isLanguageOpen, setIsLanguageOpen] = useState(
    !!filters?.language?.length
  );
  const [isTZOpen, setIsTZOpen] = useState(!!filters?.timezone);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [isInsuranceOpen, setIsInsuranceOpen] = useState(false);

  const handleSpeciality = (id) => {
    const newSelected = [...selectedSpecialies];
    const index = newSelected.findIndex((el) => el === id);
    if (index !== -1) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }
    setSelectedSpecialies([...newSelected]);
  };

  const handleLanguage = (id) => {
    const newSelected = [...selectedLanguages];
    const index = newSelected.findIndex((el) => el === id);
    if (index !== -1) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }
    setSelectedLanguages([...newSelected]);
  };

  const handleTZ = (id) => {
    if (selectedTz === id) {
      setSelectedTz(null);
      return;
    }
    setSelectedTz(id);
  };

  const applyFilters = (type = "APPLY") => {
    if (type === "RESET") {
      setSelectedLanguages([]);
      setSelectedSpecialies([]);
      setSelectedTz(null);
      setFilters(INIT_FILTERS);
      setIsFilterChanged(false);
    } else {
      setFilters({
        language: selectedLanguages,
        speciality: selectedSpecialies,
        timezone: selectedTz,
      });
      setIsFilterChanged(true);
    }
    setIsFilterOpen(false);
  };

  const isFilters = useMemo(() => {
    return (
      !!selectedLanguages.length || !!selectedSpecialies.length || !!selectedTz
    );
  }, [selectedSpecialies, selectedLanguages, selectedTz]);

  const getSubmitText = useMemo(() => {
    if (filters === INIT_FILTERS) return "Apply";
    if (
      JSON.stringify(filters) !==
      JSON.stringify({
        language: selectedLanguages,
        speciality: selectedSpecialies,
        timezone: selectedTz,
      })
    ) {
      return "Apply Changes";
    } else return "Apply";
  }, [selectedSpecialies, selectedLanguages, selectedTz, filters]);

  return (
    <FilterWrapper isMobile={isMobile}>
      <div className="font-bold design-scrollbar filter-container">
        <div className="flex ptb-24 felx-1 justify-content-between align-items-center">
          <div className="fs-30 disable-text-selection" onClick={handleFilter}>
            {t("FILTERS")}
          </div>
          {isTabletOrMobile && (
            <div className="flex justify-content-center align-items-center cursor-pointer">
              <CloseIcon
                height={20}
                width={20}
                fill="#fff"
                onClick={handleFilter}
              />
            </div>
          )}
        </div>
        <div className="h-1 border-white" />
        <div className="flex ptb-20 felx-1 flex-y">
          <div className="flex felx-1 justify-content-between align-items-center disable-text-selection">
            <div className="fs-22">{t("STATE")}</div>
            <GreaterIcon
              height={14}
              width={14}
              fill="#fff"
              className={clsx(
                "cursor-pointer rotate-90",
                isStateOpen && "rotate-270"
              )}
              onClick={() => setIsStateOpen((prev) => !prev)}
            />
          </div>
          {isStateOpen && (
            <div
              className="flex ptb-10 plr-18 align-items-center mt-12 fs-16 bg-light border-radius-20 color-dark disable-text-selection"
              style={{ fontSize: 16 }}
            >
              {state?.name}
            </div>
          )}
        </div>
        <div className="h-1 border-white" />
        <div className="flex ptb-20 felx-1 flex-y">
          <div className="flex felx-1 justify-content-between align-items-center disable-text-selection">
            <div className="fs-22">{t("INSURANCE")}</div>
            <GreaterIcon
              height={14}
              width={14}
              fill="#fff"
              className={clsx(
                "cursor-pointer rotate-90",
                isInsuranceOpen && "rotate-270"
              )}
              onClick={() => setIsInsuranceOpen((prev) => !prev)}
            />
          </div>
          {isInsuranceOpen && (
            <div
              className="flex ptb-10 plr-18 align-items-center mt-12 bg-light border-radius-20 color-dark disable-text-selection"
              style={{ fontSize: 16 }}
            >
              {insurance?.name}
            </div>
          )}
        </div>
        <div className="h-1 border-white" />
        {!opLoading ? (
          <>
            <div className="flex ptb-20 felx-1 flex-y">
              <div className="flex felx-1 justify-content-between align-items-center disable-text-selection">
                <div className="fs-22">{t("FILTER_LBL_SPECIALTY")}</div>
                <GreaterIcon
                  height={14}
                  width={14}
                  fill="#fff"
                  className={clsx(
                    "cursor-pointer rotate-90",
                    isSpecialityOpen && "rotate-270"
                  )}
                  onClick={() => setIsSpecialityOpen((prev) => !prev)}
                />
              </div>
              {isSpecialityOpen && (
                <div className="flex flex-y ptb-6">
                  {specialityOptions.map((speciality) => (
                    <div
                      className="flex align-items-center plr-6 cursor-pointer"
                      role="buton"
                      onClick={() => handleSpeciality(speciality.id)}
                    >
                      <div
                        className={clsx(
                          "h-12 w-12 border-radius-2",
                          selectedSpecialies.includes(speciality.id)
                            ? "bg-dark"
                            : "bg-white"
                        )}
                      />
                      <div
                        className="plr-6 disable-text-selection"
                        style={{ fontSize: 16 }}
                      >
                        {speciality.name}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="h-1 border-white" />
            <div className="flex ptb-20 felx-1 flex-y">
              <div className="flex felx-1 justify-content-between align-items-center disable-text-selection">
                <div className="fs-22">{t("LANGUAGE")}</div>
                <GreaterIcon
                  height={14}
                  width={14}
                  fill="#fff"
                  className={clsx(
                    "cursor-pointer rotate-90",
                    isLanguageOpen && "rotate-270"
                  )}
                  onClick={() => setIsLanguageOpen((prev) => !prev)}
                />
              </div>
              {isLanguageOpen && (
                <div className="flex flex-y ptb-6">
                  {languageOptions.map((language) => (
                    <div
                      className="flex align-items-center plr-6 cursor-pointer"
                      role="buton"
                      onClick={() => handleLanguage(language.id)}
                    >
                      <div
                        className={clsx(
                          "h-12 w-12 border-radius-2",
                          selectedLanguages.includes(language.id)
                            ? "bg-dark"
                            : "bg-white"
                        )}
                      />
                      <div
                        className="plr-6 disable-text-selection"
                        style={{ fontSize: 16 }}
                      >
                        {language?.name === "Espanol"
                          ? "Español"
                          : language?.name}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="h-1 border-white" />
            <div className="flex ptb-20 felx-1 flex-y">
              <div className="flex felx-1 justify-content-between align-items-center disable-text-selection">
                <div className="fs-22">{t("TIME_ZONE_FILTER")}</div>
                <GreaterIcon
                  height={14}
                  width={14}
                  fill="#fff"
                  className={clsx(
                    "cursor-pointer rotate-90",
                    isTZOpen && "rotate-270"
                  )}
                  onClick={() => setIsTZOpen((prev) => !prev)}
                />
              </div>

              {isTZOpen && (
                <>
                  <div className="font-normal disable-text-selection">
                    ({t("SELECT_ONE_LABEL")})
                  </div>
                  <div className="flex flex-y ptb-6">
                    {tzOptions.map((tz) => (
                      <div
                        className="flex align-items-center plr-6 cursor-pointer"
                        role="button"
                        onClick={() => handleTZ(tz.id)}
                      >
                        <div
                          className={clsx(
                            "h-12 w-12 border-radius-2",
                            selectedTz === tz.id ? "bg-dark" : "bg-white"
                          )}
                        />
                        <div
                          className="plr-6 disable-text-selection"
                          style={{ fontSize: 16 }}
                        >
                          {tz.name}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="flex ptb-20 fs-18 felx-1 flex-y disable-text-selection">
            Loading filters..
          </div>
        )}
      </div>
      <div className="button-container">
        <div className="h-1 border-white" />
        <div className="flex flex-1 fill-height justify-content-between align-items-center">
          <StyledButton
            bgColor="#fff"
            border="#fff"
            color="#5A6B80"
            className="flex flex-1 justify-content-center align-center font-bold"
            borderRadius="10px"
            p="6px"
            mr="4"
            disabled={!isFilterChanged}
            onClick={() => applyFilters("RESET")}
          >
            {t("RESET")}
          </StyledButton>
          <StyledButton
            color="#fff"
            border="#5A6B80"
            bgColor="#5A6B80"
            className="flex flex-1 justify-content-center align-center font-bold"
            borderRadius="10px"
            p="6px"
            ml="4"
            onClick={applyFilters}
            disabled={!isFilters && !isFilterChanged}
          >
            {t("APPLY")}
          </StyledButton>
        </div>
      </div>
    </FilterWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.auth.state,
    insurance: state.auth.insurance,
  };
};

export default connect(mapStateToProps)(RDFilter);
