import React from "react";
import ScheduleWrapper from "./Schedule.style";
import { withRouter } from "react-router-dom";
import ProfileImage from "../../assets/images/ProfileImage.svg";
import Button from "../common/Button/Button";
import GooglePlayImage from "../../assets/images/GooglePlayBadge.png";
import AppStoreImage from "../../assets/images/AppStoreBadge.png";
import ChargeLogo from "../../assets/images/CHARGELOGO.png";
import { googleUrl, appleUrl } from "../../helper/constants";

const Schedule = (props) => {
  let RdData = props.location.state.admin;
  const WIDTH = window.innerWidth > 1024 ? "wp-80" : "wp-100";

  return (
    <ScheduleWrapper>
      <div className="row ma-0 pa-0 device-height">
        <div
          className={
            "bg-color pa-0 col-12 col-sm-12 col-md-12 " +
            (window.innerWidth === 1024 ? "col-lg-12" : "col-lg-6")
          }
        >
          <div className="position-relative">
            <div className="setup-profile">
              <div className="text-center pb-5">
                <img
                  src={ChargeLogo}
                  className="logo"
                  alt="HUSK Nutrition"
                ></img>
              </div>
              <div className="">
                <img
                  src={
                    RdData.profile_image ? RdData.profile_image : ProfileImage
                  }
                  className="profile-image"
                  alt="profile"
                ></img>
              </div>
              <div className="Glacial-Bold fs-34 font-weight-bold mtp-1 text-center">
                {`${RdData.first_name} ${RdData.last_name}`}
              </div>
              <div className="Glacial-Regular fs-20  mtp-1 text-center">
                Your registered dietitian
              </div>
              <div className="text-center plrp-20 Glacial-Regular mt-25">
                {`${RdData.rd_detail.profile_text}`}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-12 col-sm-12 col-md-12 plrp-11  " +
            (window.innerWidth === 1024 ? "col-lg-12" : "col-lg-6")
          }
        >
          <div>
            <h1 className="Glacial-Bold jumbotrons">
              Schedule Your First Visit
            </h1>
            <div className={WIDTH}>
              <div className="timeline-wrapper mtp-20">
                <div className="node">
                  <div className=" Glacial-Bold pb-10 fs-18 font-weight-bold">
                    Schedule your first visit
                  </div>
                  <div className="Glacial-Regular pb-10 fs-18">
                    Select a date and time below. We’ll send a meeting
                    invitation with a Zoom link to get you started.
                  </div>
                  <Button
                    text="SCHEDULE NOW"
                    width="70%"
                    marginTop="0px"
                    height="40px"
                    onClick={(e) =>
                      props.history.push(
                        "/calendly",
                        RdData.rd_detail.calendly_link +
                          "?name=" +
                          props.location.state.first_name +
                          " " +
                          props.location.state.last_name +
                          "&email=" +
                          props.location.state.email +
                          "&back=0"
                      )
                    }
                  ></Button>
                </div>
                <div className="node">
                  <div className=" Glacial-Bold pb-10 fs-18 font-weight-bold">
                    Meet your Registered Dietitian
                  </div>
                  <div className="Glacial-Regular pb-10 fs-18">
                    Work one-on-one with your Registered Dietitian to create a
                    customized nutrition plan that meets your needs.
                  </div>
                </div>
                <div className="node">
                  <div className=" Glacial-Bold pb-10 fs-18 font-weight-bold">
                    Download our App
                  </div>
                  <div className="Glacial-Regular pb-10 fs-18">
                    Start using the mobile application today to view your visit
                    history, message your Registered Dietitian and create goals.
                  </div>
                </div>
              </div>
              <div className="app-icon mtp-6 ">
                <img
                  className="cursor-pointer"
                  src={GooglePlayImage}
                  alt="Play store icon"
                  onClick={(e) => {
                    window.open(googleUrl);
                  }}
                ></img>
                <img
                  className="mlp-3 cursor-pointer"
                  src={AppStoreImage}
                  alt="App store icon"
                  onClick={(e) => {
                    window.open(appleUrl);
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScheduleWrapper>
  );
};

export default withRouter(Schedule);
