import { store } from "../redux/store";
import axios from "axios";

const LANGUAGE = {
  "en-US": "en",
  en: "en",
  es: "es",
};

class Headers {
  static setHeaders = () => {
    if (store.getState().auth.accessToken)
      axios.defaults.headers.common["Authorization"] =
        store.getState().auth.accessToken;
  };

  static setLangHeaders = (lng) => {
    axios.defaults.headers.common["Accept-Language"] = LANGUAGE[lng];
  };

  static setTzHeader = () => {
    axios.defaults.headers.common["timezone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
  };
}

export default Headers;
