import React, { useEffect, useRef, useState } from "react";
import AuthServices from "../../api/AuthServices";
import { useTranslation } from "react-i18next";
import { LanguageIcon } from "../../icons";
import clsx from "clsx";
import { useMedia } from "../../hook/useMedia";

const LANGUAGE = {
  "en-US": "en",
  en: "en",
  es: "es",
};

export const LanguageSelector = () => {
  const ref = useRef(null);
  const { i18n } = useTranslation();
  const { isAfterTablet } = useMedia();

  const [currentLanguage, setCurrentLanguage] = useState({
    id: 1,
    name: "English",
    code: "en",
  });
  const [languageList, setLanguageList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AuthServices.getLanguages().then((res) => {
      setLanguageList([...res?.data]);
      const lang =
        res?.data?.length &&
        res?.data?.find((el) => el?.code === LANGUAGE[i18n.language]);
      setCurrentLanguage(lang);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleChange = async (data) => {
    i18n.changeLanguage(data.code);
    setCurrentLanguage(data);
    setIsOpen((prev) => !prev);
    let params = {
      language: LANGUAGE[data.code],
    };
    await AuthServices.changeLanguage(params);
  };

  return (
    <div className="pos-relative">
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={clsx(
          "border fs-16 border-dark flex border-radius-6 w-164 cursor-pointer align-items-center",
          isAfterTablet ? "pa-2" : "pa-6"
        )}
      >
        <LanguageIcon height={16} width={16} className="pr-1" />
        Language:
        <span className="color-dark pl-1 font-weight-bold">
          {currentLanguage?.name}
        </span>
      </div>
      {isOpen && (
        <div
          ref={ref}
          className="pos-absolute w-164 mt-1 ptb-2 plr-2 border-radius-4 border-dark border"
        >
          {languageList.length > 0 &&
            languageList.map((el) => (
              <div
                className="cursor-pointer languagePopup"
                onClick={() => handleChange(el)}
              >
                {el.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
