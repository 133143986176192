import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .trim("SPACE_VALIDATION")
      .strict(true)
      .matches(/^[A-Za-z0-9 ]+$/, "SPECIAL_CHAR_VALIDATION")
      .required("FIRST_NAME_REQ_VALIDATION"),
    email: Yup.string()
      .trim()
      .email("VALID_EMAIL_VALIDATION")
      .required("EMAIL_REQ_VALIDATION"),
    lastName: Yup.string()
      .trim("SPACE_VALIDATION")
      .strict(true)
      .matches(/^[A-Za-z0-9 ]+$/, "SPECIAL_CHAR_VALIDATION")
      .required("LAST_NAME_REQ_VALIDATION"),
    registrationCode: Yup.string()
      .trim()
      .required("REGISTRATION_CODE_VALIDATION"),
  }),
  handleSubmit: (values) => {},
  mapPropsToValues: (props) => ({}),
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: false,
});

export default formikEnhancer;
