import styled from "styled-components";

const LandingPageWrapper = styled.div`
  margin: 0;
  .device-height {
    @media (max-width: 1024px) {
      padding-bottom: 10%;
      padding-top: 15%;
    }
    @media (min-width: 1025px) {
      height: 100vh;
    }
  }
  .set-image {
    height: 100%;
    object-fit: cover;
    @media (max-width: 1024px) {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    @media (min-width: 1025px) {
      min-height: 100vh;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .code {
    position: absolute;
    bottom: 12%;
    right: 0;
    border-bottom: 1px solid #5837d1;
    line-height: 1;
    color: #5837d1;
  }
  .jumbotrons {
    padding-top: 7%;
    color: #5A6B80;
    font-size: 3.5rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 2.3rem;
    }
  }
  .app-icon {
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
      padding-bottom: 20%;
    }
  }
  .logo {
    top: 30px;
    left: 25%;
    @media (min-width: 719px) and (max-width: 1024px) {
      top: 15px;
      left: 40%;
    }
    @media (max-width: 718px) {
      top: 15px;
      left: 30%;
    }
  }
  .bg-color {
    background-color: #f5f9e6;
  }
  .profile-image {
    border-radius: 50%;
    height: 250px;
    width: 250px;
    object-fit: contain;
  }
  .setup-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .timeline-wrapper {
    border-left: 2px dashed #e8e8e8;
  }
  .node {
    padding-left: 2rem;
    padding-bottom: 1.5rem;
    position: relative;
  }
  .node::before {
    content: "";
    width: 0.7rem;
    height: 0.7rem;
    background: #fff;
    border: 2px solid #AECE38;
    border-radius: 50%;
    position: absolute;
    left: -0.4rem;
    background-color: #f5f9e6;
    top: 3px;
  }
  .app-icon {
    display: flex;
    justify-content: center;
    padding-bottom: 20%;
    @media (max-width: 1024px) {
      padding-bottom: 20% !important;
    }
    @media (min-width: 1375px) and (max-width: 1500px) {
      padding-left: 22%;
    }
    @media (min-width: 1501px) and (max-width: 1700px) {
      padding-left: 11%;
    }
    @media (min-width: 1025px) and (max-width: 1374px) {
      padding-left: 28%;
      padding-bottom: 10%;
    }
  }
`;

export default LandingPageWrapper;
