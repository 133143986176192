import React from "react";
import SlotsPageWrapper from "./schedule_appointment.style";
import { useEffect, useState } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { connect } from "react-redux";
import clsx from "clsx";
import { useMedia } from "../../hook/useMedia";
import Logo from "../../assets/images/CHARGELOGO.png";
import Footer from "../common/Footer/Footer";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";

const ScheduleAppointment = (props) => {
  const { isTabletOrMobile, isDesktopOrLaptop, isMobile } = useMedia();
  const { t } = useTranslation();
  const [displayButton, setDisplayButton] = useState(false);

  const data = props.location.state;

  useEffect(() => {
    if (!data) {
      props.history.push("/rd-slots");
    }
  }, [data]);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      setDisplayButton(true);
    },
  });

  const handleContinue = () => {
    props.history.push("/appointment-confirmation");
  };

  return (
    <SlotsPageWrapper>
      <div className="pos-relative">
        <div className="pos-absolute" style={{ right: 12, top: 4 }}>
          <LanguageSelector />
        </div>
        <div>
          <div className="flex-100 pt-18">
            <div>
              <img src={Logo} height={"100%"} width="100%" alt={"Husk Logo"} />
            </div>
          </div>
        </div>
        {displayButton && (
          <>
            <div
              className={clsx(
                "Glacial-Bold jumbotrons fs-24 pa-12 text-center",
                !isTabletOrMobile && "fs-34 pa-20"
              )}
            >
              {t("APPT_CONFIRMED_TEXT")}
            </div>
            <div className="flex-100 plr-32 justify-center align-center">
              <div
                className={clsx(
                  "flex cursor-pointer justify-center align-center bg-green w-280 color-white border-radius-8 ptb-8  fs-16 font-bold",
                  isMobile && "w-100"
                )}
                onClick={handleContinue}
              >
                {t("CONTINUE_BUTTON")}
              </div>
            </div>
          </>
        )}
        <InlineWidget
          url={data?.scheduling_url}
          prefill={{
            email: props.email,
            name: props.first_name + " " + props.last_name,
          }}
          styles={{
            height: "100vh",
          }}
        />
      </div>
      <Footer width={100}></Footer>
    </SlotsPageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    first_name: state.auth.firstName,
    last_name: state.auth.lastName,
    email: state.auth.email,
  };
};

export default connect(mapStateToProps)(ScheduleAppointment);
