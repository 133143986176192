import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer(props) {
  let { width } = props;
  const { t } = useTranslation();

  return (
    <div
      style={{
        // background: "#aece38",
        position: "fixed",
        bottom: "0",
        width: `${width}%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 20px",
      }}
      className="bg-dark-gray"
    >
      <div className="Monts-Medium fs-14 color-white">
        {t("FOOTER_TEXT")}{" "}
        <a
          href="mailto:support@huskwellness.com"
          className="color-white underline"
        >
          support@huskwellness.com
        </a>
      </div>
      {/* <div>
        <a
          href="/help"
          className="Monts-Medium fs-12"
          style={{ color: "white" }}
        >
          Help
        </a>
        &nbsp; &nbsp;
        <a
          href="/faq"
          className="Monts-Medium fs-12"
          style={{ color: "white" }}
        >
          FAQ
        </a>
        &nbsp; &nbsp;
        <a
          href="/terms"
          className="Monts-Medium fs-12"
          style={{ color: "white" }}
        >
          Terms & Conditions
        </a>
        &nbsp; &nbsp;
        <a
          href="/privacy-policy"
          className="Monts-Medium fs-12"
          style={{ color: "white" }}
        >
          Privacy Policy
        </a>
      </div> */}
    </div>
  );
}
