const authActions = {
  login: (data) => {
    return {
      type: "LOGIN",
      isLogin: true,
      accessToken: data.accessToken,
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      state: data.state,
      insurance: data.insurance,
    };
  },

  userData: (data) => {
    return {
      type: "USER_DATA",
      firstName: data.firstName,
      lastName: data.lastName,
      Image: data.image,
      user_data: data.data,
    };
  },

  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
    };
  },

  discovery: () => {
    return {
      type: "DISCOVERY",
    };
  },
};

export default authActions;
