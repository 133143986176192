import React, { useEffect, useRef } from "react";
import SlotsPageWrapper from "./Slots.style";
import Logo from "../../assets/images/CHARGELOGO.png";
import { useState } from "react";
import { useMedia } from "../../hook/useMedia";
import {
  format,
  startOfWeek,
  addDays,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  subDays,
} from "date-fns";
import moment from "moment-timezone";
import clsx from "clsx";
import { ArrowLeft, Loader, BarFilterIcon, WeekArrowIcon } from "../../icons";
import AuthenticationApiSerivce from "../../api/AuthServices";
import Footer from "../common/Footer/Footer";
import { StyledButton } from "../common/StyledButton";
import { INPERSON_VISIT_TYPE, PREFERENCE_TYPE } from "../../helper/constants";
import { message } from "antd";
import ProfileImage from "../../assets/images/userPlaceholder.png";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";
import axios from "axios";

const Slots = (props) => {
  const {
    isTabletOrMobile,
    isDesktopOrLaptop,
    isMobile,
    isAfterTablet,
    isAfterDesktop,
  } = useMedia();
  const { t } = useTranslation();
  const locRef = useRef(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultRd, setDefaultRd] = useState(false);
  const [isSettingDRD, setIsSettingDRD] = useState(true);
  const [startEndWeek, setStartEndWeek] = useState({
    start: null,
    end: null,
    today: null,
  });
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [locationIds, setLocationIds] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [inPersonVisit, setInPersonVisit] = useState(false);

  const type = props?.location?.state?.type;
  const rd = props?.location?.state;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (locRef.current && !locRef.current.contains(event.target)) {
        setIsLocationOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isLocationOpen]);

  useEffect(() => {
    if (type === PREFERENCE_TYPE.ALL && rd?.id) {
      const param = {
        rd_id: rd?.id,
      };

      AuthenticationApiSerivce.rdLocationListByRdId(param)
        .then((res) => {
          setLocationList([...res.data]);
        })
        .catch((err) =>
          message.error("Something went wrong! Please try again.")
        );
    }
  }, [type, rd?.id]);

  useEffect(() => {
    if (!type) props.history.push("/preference");
    if (type === PREFERENCE_TYPE.BY_TIME) {
      setDefaultRd(!props?.location?.state.is_rd_available);
    }
    setIsSettingDRD(false);
  }, [type, props?.location?.state]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (!isSettingDRD) {
      setStartEndWeek({
        start: currentWeek,
        end: getWeek(addWeeks(currentMonth, 7)),
        today: selectedDate,
      });

      fetchSlot(currentMonth, ourRequest);
    }
    return () => {
      ourRequest.cancel(); // <-- 3rd step
    };
  }, [isSettingDRD, locationIds]);

  const toggleLocation = () => {
    setIsLocationOpen((prev) => !prev);
  };

  const fetchSlot = (currentM, ourRequest) => {
    setLoading(true);
    setSlots([]);
    let param = {
      start_date: moment(startOfWeek(currentM, { weekStartsOn: 1 })).format(
        "YYYY-MM-DD"
      ),
      end_date: moment(
        subDays(lastDayOfWeek(currentM, { weekStartsOn: 1 }), 2)
      ).format("YYYY-MM-DD"),
      timezone: moment.tz.guess(),
    };

    if (!defaultRd) {
      if (type === PREFERENCE_TYPE.BY_TIME) {
        AuthenticationApiSerivce.rdSlotList(param)
          .then((res) => {
            setSlots(res.data.rd_slot);
            setLoading(false);
          })
          .catch((err) =>
            message.error("Something went wrong! Please try again.")
          );
      } else if (type === PREFERENCE_TYPE.ALL) {
        const locations = [];
        const loc = [...locationIds];
        loc.forEach((el) => locations.push(el.id));
        param.id = rd?.id;
        let cT = {};

        if (ourRequest?.token) {
          cT.cancelToken = ourRequest?.token;
        }

        if (locationIds?.length) {
          param.location_id = String(locations);
        }

        AuthenticationApiSerivce.rdSlotListById(param, cT)
          .then((res) => {
            setSlots(res.data.rd_slot);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response)
              message.error("Something went wrong! Please try again.");
          });
      }
    } else {
      param.is_default_rd = true;
      AuthenticationApiSerivce.rdSlotListById(param)
        .then((res) => {
          setSlots(res.data.rd_slot);
          // setDefaultRd(res.data.is_default_rd);
          setLoading(false);
        })
        .catch((err) =>
          message.error("Something went wrong! Please try again.")
        );
    }
  };

  const utcToLocal = (date) => {
    return moment.tz(date, moment.tz.guess()).format("hh:mm A");
  };

  const changeWeekHandle = (btnType) => {
    let currentM = "";
    if (btnType === "prev") {
      if (startEndWeek.start === getWeek(currentMonth)) return;
      currentM = subWeeks(currentMonth, 1);
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      if (startEndWeek.end === getWeek(currentMonth)) return;
      currentM = addWeeks(currentMonth, 1);
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
    fetchSlot(currentM);
  };

  const onDateClickHandle = (day) => {
    if (
      moment(day).format("MM-DD-YYYY") <
      moment(startEndWeek.today).format("MM-DD-YYYY")
    ) {
      return;
    }
    setSelectedDate(day);
    // fetchSlot(day);
  };

  const onSelectSlot = (slot) => {
    setSelectedSlot(slot);
  };
  const handleNext = () => {
    if (!inPersonVisit && selectedSlot?.type === INPERSON_VISIT_TYPE) {
      setInPersonVisit(true);
    } else {
      if (type === PREFERENCE_TYPE.BY_TIME && !defaultRd)
        props.history.push("/rd-list", { ...selectedSlot, type });
      else if (type === PREFERENCE_TYPE.ALL || defaultRd)
        props.history.push("/schedule-appointment", {
          ...selectedSlot,
          ...props?.location.state,
          type,
        });
    }
  };

  const handleBack = (toFilter = false) => {
    if (toFilter) {
      props.history.push("/rd-list", { type: PREFERENCE_TYPE.ALL });
      return;
    }
    if (inPersonVisit && selectedSlot?.type === INPERSON_VISIT_TYPE) {
      setInPersonVisit(false);
    } else {
      props.history.push(
        type === PREFERENCE_TYPE.ALL ? "/rd-list" : "/preference",
        { type }
      );
    }
  };

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <div className="flex plr-12 ptb-4 justify-center align-center font-bold color-dark fs-20 pb-8">
        <div>{format(currentMonth, dateFormat)}</div>
      </div>
    );
  };

  const renderDays = () => {
    const dayFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = subDays(
      lastDayOfWeek(currentMonth, { weekStartsOn: 1 }),
      2
    );
    const dateFormat = "d";
    const rows = [];
    let dates = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 5; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        dates.push(
          <div
            className={clsx(
              "flex flex-1 justify-center align-center plr-8 ptb-6 flex-column mlr-2 border-radius-10 font-bold",
              moment(day).format("MM-DD-YYYY") ===
                moment(selectedDate).format("MM-DD-YYYY") &&
                "bg-dark color-white",
              isTabletOrMobile ? "fs-14" : "fs-18",
              moment(day).format("MM-DD-YYYY") <
                moment(startEndWeek.today).format("MM-DD-YYYY") &&
                "cursor-not-allowed"
            )}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <div
              className={clsx(
                "pt-6",
                moment(day).format("MM-DD-YYYY") ===
                  moment(selectedDate).format("MM-DD-YYYY") && "color-white"
              )}
            >
              {formattedDate}
            </div>
            <div
              className={clsx(
                moment(day).format("MM-DD-YYYY") ===
                  moment(selectedDate).format("MM-DD-YYYY") && "color-white"
              )}
            >
              {format(addDays(startDate, i), dayFormat)}
            </div>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="flex flex-1 align-center space-between" key={day}>
          {dates}
        </div>
      );
      dates = [];
    }

    for (let i = 0; i < 5; i++) {
      days.push(
        <div
          className="flex flex-1 flex-column justify-center align-center ptb-8"
          key={i}
        >
          <div>{format(addDays(startDate, i), dayFormat)}</div>
        </div>
      );
    }
    return (
      <div className="flex align-center flex-1 space-between plr-2 bg-green color-white">
        <div
          className={clsx(
            "cursor-pointer plr-2 flex fill-height justify-center align-center w-36",
            isDesktopOrLaptop && "plr-8 w-132"
          )}
          onClick={() => changeWeekHandle("prev")}
        >
          {startEndWeek.start !== getWeek(currentMonth) && (
            <>
              <WeekArrowIcon
                className={clsx(
                  "rotate-180",
                  startEndWeek.start === getWeek(currentMonth) &&
                    "cursor-not-allowed"
                )}
                height={36}
                width={36}
                fill={
                  startEndWeek.start !== getWeek(currentMonth)
                    ? "#ffffff"
                    : "#fecdd3"
                }
              />
              {isDesktopOrLaptop && <div className="fs-18">PREVIOUS</div>}
            </>
          )}
        </div>
        <div className="flex flex-1 space-between fill-width cursor-pointer">
          {rows}
        </div>
        <div
          className={clsx(
            "cursor-pointer plr-2 flex fill-height justify-center align-center w-36",
            isDesktopOrLaptop && "plr-8 w-132"
          )}
          onClick={() => changeWeekHandle("next")}
        >
          {startEndWeek.end !== getWeek(currentMonth) && (
            <>
              {isDesktopOrLaptop && <div className="fs-18">NEXT</div>}
              <WeekArrowIcon
                className={clsx(
                  startEndWeek.end === getWeek(currentMonth) &&
                    "cursor-not-allowed"
                )}
                height={36}
                width={36}
                fill={
                  startEndWeek.end !== getWeek(currentMonth)
                    ? "#ffffff"
                    : "#fecdd3"
                }
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const getLocationString = () => {
    const STRING = {
      0: t("ALL_LOCATIONS_TEXT"),
      1: locationIds[0]?.location,
    };
    if (locationIds.length < 2) {
      return STRING[locationIds?.length];
    } else {
      return `${locationIds.length} ${t("SELECTED_TXT")}`;
    }
  };

  const handleSelectLocations = (loc) => {
    const locIds = [...locationIds];
    const index = locIds.findIndex((el) => el?.id === loc?.id);
    if (index !== -1) {
      locIds.splice(index, 1);
    } else {
      locIds.push(loc);
    }
    setLocationIds([...locIds]);
  };

  return (
    <SlotsPageWrapper>
      <div className="flex flex-y flex-1 pos-relative">
        <div className="pos-absolute" style={{ right: 12, top: 4 }}>
          <LanguageSelector />
        </div>
        <div className="flex-100 pt-18">
          <div>
            <img src={Logo} height={"100%"} width="100%" alt={"Husk Logo"} />
          </div>
        </div>
        <div className="d-flex flex-y ">
          <>
            {inPersonVisit ? (
              <div
                className={clsx(
                  "flex justify-content-center align-items-center text-center color-dark",
                  isTabletOrMobile ? "fs-20" : "fs-28"
                )}
                style={{
                  height: "64vh",
                  maxHeight: "64vh",
                  paddingInline: isTabletOrMobile ? 32 : 180,
                }}
              >
                {t("YOU_HAVE_SELECTED_TEXT")}{" "}
                {moment
                  .tz(selectedSlot?.start_time, moment.tz.guess())
                  .format("LT")}{" "}
                {t("ON")}{" "}
                {moment
                  .tz(selectedSlot?.start_time, moment.tz.guess())
                  .format("dddd, MMMM Do, YYYY")}
                . {t("APPT_INPERSON_AT_TEXT")} {selectedSlot?.location}.{" "}
                {t("CLICK_NEXT_TO_CONTINUE_TEXT")}
              </div>
            ) : (
              <>
                {type === PREFERENCE_TYPE.BY_TIME ? (
                  <div
                    className={clsx(
                      "Glacial-Bold jumbotrons fs-24 pa-12",
                      !isTabletOrMobile && "fs-34 pa-32"
                    )}
                  >
                    {defaultRd
                      ? t("DEFAULT_RD_SLOT_HEADING")
                      : t("ALL_RD_SLOT_LIST_HEADING_LABEL")}
                  </div>
                ) : (
                  <>
                    <div className="flex justify-content-between align-center">
                      <div
                        className={clsx(
                          "Glacial-Bold jumbotrons fs-20 pa-12",
                          !isTabletOrMobile && "fs-34 pa-32"
                        )}
                      >
                        {t("SELECT_SLOT_BY_RD_TEXT")} {rd?.first_name ?? ""}{" "}
                        {rd?.last_name ?? ""}
                      </div>

                      <div
                        className={clsx(
                          "flex flex-y justify-content-center align-center plr-12",
                          !isTabletOrMobile && "plr-32"
                        )}
                      >
                        <div
                          className={clsx(
                            "border-round h-64 w-64",
                            !isTabletOrMobile && " h-90 w-90"
                          )}
                        >
                          <img
                            src={rd?.profile_image ?? ProfileImage}
                            height={"100%"}
                            width={"100%"}
                            className="border-round"
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                        <div
                          className={clsx(
                            "Glacial-Bold jumbotrons text-center fs-20 pt-8 color-dark",
                            !isTabletOrMobile && "fs-30"
                          )}
                        >
                          {rd?.first_name} {rd?.last_name}
                        </div>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        "plr-12 pb-1 flex align-center pos-relative",
                        !isTabletOrMobile && "plr-32"
                      )}
                    >
                      {locationList.length > 1 && (
                        <>
                          <div
                            role="button"
                            className="disable-text-selection bg-orange border-radius-16 plr-28 ptb-2 color-white fs-18 cursor-pointer flex  align-content-center justify-content-center"
                            onClick={toggleLocation}
                            style={{
                              minWidth: 260,
                              maxWidth: 360,
                            }}
                          >
                            <BarFilterIcon width={20} fill="#fff" />
                            <div style={{ paddingLeft: 6 }}>
                              {t("LOCATION_TEXT")}: {getLocationString()}
                            </div>
                          </div>

                          {isLocationOpen && (
                            <div
                              ref={locRef}
                              className="pos-absolute flex flex-column bg-white border-husk border-radius-4 plr-12 ptb-2"
                              style={{ top: 34, width: 320 }}
                            >
                              {locationList.length > 0 &&
                                locationList.map((el) => (
                                  <div
                                    className="color-dark flex align-items-center cursor-pointer"
                                    role="button"
                                    onClick={() => {
                                      handleSelectLocations(el);
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 14,
                                        width: 14,
                                        marginRight: 4,
                                        borderRadius: 4,
                                        backgroundColor: locationIds.find(
                                          (loc) => loc?.id === el?.id
                                        )
                                          ? "#3f3f40"
                                          : "#d4d4d4",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <div>{el?.location}</div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
                <div className="flex fill-width flex-column">
                  {renderHeader()}
                  {renderDays()}
                </div>
                {/* {!loading && !!slots.length && (
            <div
              className={clsx(
                "flex flex-1 flex-wrap ptb-32 plr-32",
                !isDesktopOrLaptop && "plr-12 ptb-20"
              )}
            >
              {slots.map((slot) => (
                <div className="mb-8 pa-6">
                  <div
                    className={clsx(
                      "border-radius-10 flex justify-center align-center cursor-pointer font-bold ptb-6 w-90",
                      !!selectedSlot &&
                        selectedSlot.start_time === slot.start_time &&
                        "border-orange color-white bg-orange",
                      !!selectedSlot &&
                        selectedSlot.start_time !== slot.start_time &&
                        "border border-dark color-dark",
                      !selectedSlot && "border border-dark color-dark",
                      !isMobile && "w-110"
                    )}
                    role="button"
                    onClick={() => onSelectSlot(slot)}
                  >
                    {utcToLocal(slot.start_time)}
                  </div>
                </div>
              ))}
            </div>
          )} */}
                <div className="flex fill-width ptb-18">
                  <div
                    className={clsx("plr-18", isDesktopOrLaptop && "plr-70")}
                  ></div>
                  {slots.map((slot) => (
                    <div
                      className={clsx(
                        "flex flex-1 mlr-1  flex-column align-center",
                        !isMobile && "mlr-0"
                      )}
                      // style={{ borderRight: "1px solid" }} justify-content-center
                    >
                      {slot.slots.length ? (
                        slot.slots.map((slot) => (
                          <div className="pos-relative">
                            <div
                              className={clsx(
                                "border-radius-10 mt-2 flex justify-center align-center cursor-pointer font-bold timeSlot",
                                !!selectedSlot &&
                                  selectedSlot === slot &&
                                  "border-orange color-white bg-orange",
                                !!selectedSlot &&
                                  selectedSlot !== slot &&
                                  "border border-dark color-dark",
                                !selectedSlot &&
                                  "border border-dark color-dark",
                                isDesktopOrLaptop && "w-110 ptb-6",
                                isMobile &&
                                  "w-60 fs-10 border-radius-8 ptb-4 plr-2",
                                (isTabletOrMobile || isAfterDesktop) &&
                                  !isMobile &&
                                  "w-80 ptb-6",
                                isAfterTablet &&
                                  !isMobile &&
                                  "w-80 fs-10 border-radius-8 ptb-4 plr-1"
                              )}
                              role="button"
                              onClick={() => onSelectSlot(slot)}
                            >
                              {utcToLocal(slot.start_time)}
                            </div>
                            {rd?.id && rd?.type === PREFERENCE_TYPE.ALL && (
                              <div
                                className="pos-absolute location "
                                style={{
                                  left: isDesktopOrLaptop
                                    ? 114
                                    : isMobile
                                    ? 4
                                    : isAfterTablet && 20,
                                  top: isDesktopOrLaptop && 0,
                                }}
                              >
                                {slot?.location}
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="flex justify-content-center text-center align-center light-blue mt-2">
                          {/* No Slots */}
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className={clsx("plr-18", isDesktopOrLaptop && "plr-70")}
                  ></div>
                </div>
                {!loading && !slots.length && (
                  <div className="flex-100 justify-center fs-18 color-dark ptb-20 plr-4 text-center">
                    {t("NO_SLOT_AVAIL_WEEK")}
                  </div>
                )}
                {loading && (
                  <div className="flex-100 justify-center fs-18 light-blue ptb-20">
                    <Loader height="72px" width="72px"></Loader>
                  </div>
                )}
              </>
            )}
          </>

          <div>
            <div
              className={clsx(
                "flex flex-x flex-1  align-items-center plr-12 mb-56",
                !isTabletOrMobile && "plr-50",
                defaultRd || props?.location?.state?.providerCode
                  ? "justify-content-end"
                  : "justify-content-between",
                defaultRd &&
                  !props?.location?.state?.notShowBack &&
                  "justify-content-between",
                inPersonVisit && "justify-content-between"
              )}
            >
              {((!defaultRd && !props?.location?.state?.providerCode) ||
                (defaultRd && !props?.location?.state?.notShowBack) ||
                inPersonVisit) && (
                <StyledButton
                  py={isTabletOrMobile ? "6" : "8"}
                  px={isTabletOrMobile ? "8" : "12"}
                  mt="10"
                  mb="60"
                  borderRadius="8px"
                  color="#fff"
                  bgColor="#AECE38"
                  hoverColor="#AECE38"
                  border={"1px solid #AECE38"}
                  width={isTabletOrMobile ? "120px" : "160px"}
                  className="font-bold glacial"
                  onClick={() =>
                    handleBack(defaultRd && type === PREFERENCE_TYPE.BY_TIME)
                  }
                  iconLeft={ArrowLeft}
                >
                  {t("BACK_BUTTON")}
                </StyledButton>
              )}
              <StyledButton
                py={isTabletOrMobile ? "6" : "8"}
                px={isTabletOrMobile ? "8" : "12"}
                mt="10"
                mb="60"
                borderRadius="8px"
                color="#fff"
                bgColor="#AECE38"
                hoverColor="#AECE38"
                border={"1px solid #AECE38"}
                width={isTabletOrMobile ? "120px" : "160px"}
                className="font-bold glacial"
                onClick={handleNext}
                disabled={!selectedSlot}
                iconRight={ArrowLeft}
              >
                {t("CONFIRM_BTN")}
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
      <Footer width={100}></Footer>
    </SlotsPageWrapper>
  );
};

export default Slots;
