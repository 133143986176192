import React, { Fragment, useState, useEffect, useRef } from "react";
import InsurenceWrapper from "./Insurence.style";
import { withRouter } from "react-router-dom";
import imagePlaceholder from "../../assets/images/imagePlaceholder.png";
import { Input } from "reactstrap";
import ZipInput from "../Input/Input";
import Button from "../../components/common/Button/Button";
import Image1 from "../../assets/images/Group.svg";
import Image2 from "../../assets/images/Group2.svg";
import Image3 from "../../assets/images/Group3.svg";
import QuestionImage from "../../assets/images/Vector.jpg";
import CloseImage from "../../assets/images/Close.svg";
import AuthenticationApiSerivce from "../../api/AuthServices";
import enhancer from "./Insurance.validator";
import { DatePicker, message } from "antd";
import AuthActions from "../../redux/auth/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { useMedia } from "../../hook/useMedia";
import { PREFERENCE_TYPE, SELF_PAY_INSURANCE_ID } from "../../helper/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { RegistrationLayout } from "../RegistrationLayout/RegistrationLayout";
import { DeleteIcon } from "../../icons/Delete";
import EncryptDecrypt from "../../api/Encryption";

export const ImageResolution = {
  HEIGHT: 100,
  WIDTH: 100,
};

const CategoryListArray = [
  {
    id: 1,
    image: Image1,
    name: "WEIGHT_MANAGEMENT", // "Weight Management",
    info: "WEIGHT_MANAGEMENT_TOOLTIP", // "Select this option if your primary focus is on gaining, losing or maintaining your weight.",
  },
  {
    id: 2,
    image: Image2,
    name: "DISEASE_MANAGEMENT", //"Disease Management",
    info: "DISEASE_MANAGEMENT_TOOLTIP", //"Select this option if you are trying to manage or prevent a chronic disease, such as diabetes, heart disease, etc.",
  },
  {
    id: 3,
    image: Image3,
    name: "HEALTHY_LIFESTYLE", //"Healthy Lifestyle",
    info: "HEALTHY_LIFESTYLE_TOOLTIP", // "Select this option if you are looking to create healthy habits to improve your overall wellness.",
  },
];

const { login } = AuthActions;

const Insurence = (props) => {
  const frontRef = useRef(null);
  const backRef = useRef(null);
  const { isLaptop, isLg, isTabletOrMobile, isMobile, isTbLg } = useMedia();
  const { t, i18n } = useTranslation();

  const WIDTH = !isLaptop ? "wp-55" : "wp-94";

  const {
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldTouched,
    setFieldError,
  } = props;

  const [QuestionPopup, setQuestionPopup] = useState(false);
  const [InsurenceData, setInsurenceData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [imageObj, setImageObj] = useState({
    front_insurance_image: null,
    back_insurance_image: null,
  });

  useEffect(() => {
    if (!props.location.state) {
      props.history.push("/register");
    } else {
      AuthenticationApiSerivce.insurenceList(
        props?.location?.state?.registrationCode
      )
        .then((res) => {
          setInsurenceData(res.data);
          setFieldValue("insurance", "");
        })
        .catch((err) => {
          message.error("Failed to fetch insurance list! Please try again.");
        });
    }
    // let params = { type: "" };
    // AuthenticationApiSerivce.staticPages(params).then((res) => {
    //   setTerms(res.data);
    // });
    // let params = { type: "terms" };
    // AuthenticationApiSerivce.staticPages(params).then((res) => {
    //   setTerms(res.data);
    // });
  }, [props.location.state]);

  useEffect(() => {
    if (
      values?.insurance === SELF_PAY_INSURANCE_ID ||
      values?.insurance === "0"
    ) {
      setFieldValue("primarySubs", "");
      setFieldValue("isIbo", false);
    }
  }, [values?.insurance]);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={"Glacial-Regular text-danger fs-14 pt-1"}>
          {t(errors[field1])}
        </div>
      );
    } else {
      return <span />;
    }
  };

  const insurenceChange = (value) => {
    setFieldValue("insurence", value.target.value);
  };

  const onSubmitOfInsurence = (e) => {
    let { values, isValid, handleSubmit } = props;
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      let signUpParams = {
        email: props.location.state.email,
        first_name: props.location.state.firstName,
        last_name: props.location.state.lastName,
        registration_code: props.location.state.registrationCode,
        category_id: values.category_id,
        zipcode: values.zipCode,
        device_type: "web",
        isCheckedAcknowledgements: values?.isCheckedAcknowledgements,
        timezone: moment.tz.guess(),
      };
      if (
        (values?.insurance === SELF_PAY_INSURANCE_ID &&
          props?.location?.state?.providerType === "ibo") ||
        (!!values?.insurance &&
          values?.insurance !== SELF_PAY_INSURANCE_ID &&
          props?.location?.state?.providerType !== "ibo" &&
          values?.insurance !== "0")
      ) {
        signUpParams.insurance_id = values.insurance;
      }
      if (
        !!values?.insurance &&
        values?.insurance !== "0" &&
        values?.insurance !== SELF_PAY_INSURANCE_ID &&
        props?.location?.state?.providerType === "ibo"
      ) {
        signUpParams.insurance_id = values.insurance;
        signUpParams.is_primary_subscriber = values?.primarySubs;
        signUpParams.group_id = values?.groupId;
        signUpParams.member_id = values?.memberId;
        signUpParams.primary_subscriber_first_name = values?.psFirstName
          ? values?.psFirstName
          : "";
        signUpParams.primary_subscriber_last_name = values?.psLastName
          ? values?.psLastName
          : "";
        signUpParams.primary_subscriber_dob = values?.psDOB
          ? moment(values?.psDOB).format("MM/DD/YYYY")
          : "";
      }

      setLoading(true);

      const formdata = new FormData();
      const encryptData = EncryptDecrypt.encrypt(signUpParams);
      formdata.append("data", encryptData?.data);
      if (imageObj?.front_insurance_image)
        formdata.append("data1", imageObj?.front_insurance_image);
      if (imageObj?.back_insurance_image)
        formdata.append("data2", imageObj?.back_insurance_image);

      AuthenticationApiSerivce.signup(formdata, i18n.language)
        .then((res) => {
          if (res.status) {
            let loginData = {
              accessToken: res?.data?.access_token,
              userId: res?.data?.id,
              firstName: res?.data?.first_name,
              lastName: res?.data?.last_name,
              email: res?.data?.email,
              isLogin: true,
              state: res?.data?.state,
              insurance: res.data?.user_insurance?.insurance,
            };
            props.login(loginData);
            // if (res.data.rd_id !== null) {
            //   AuthenticationApiSerivce.registerUserGetStream().then((res1) => {
            //     AuthenticationApiSerivce.sendMessageFromRD().then((res2) => {
            //       props.history.push("/schedule", res.data);
            //       setLoading(false);
            //       localStorage.removeItem("data");
            //     });
            //   });
            // } else {

            localStorage.removeItem("data");
            if (res.data.user_rd_service.admin_id) {
              props.history.push("/rd-slots", {
                ...res.data.user_rd_service.admin,
                type: PREFERENCE_TYPE.ALL,
                providerCode: true,
              });
            } else if (res.data.is_rd_available) {
              props.history.push("/preference", res.data);
            } else {
              props.history.push("/discovery-queue");
            }
            // }
            setLoading(false);
          } else {
            message.error(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          message.error("Something went wrong! Please try again.");
          setLoading(false);
        });
    }
  };

  const handleFrontImage = async (e, setValue, name) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj.size > 10 * 1024 * 1024) {
        message.error(`${t("IMAGE_SIZE_VALIDATION")}`);
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        message.error(`${t("IMAGE_TYPE_VALIDATION")}`);
        return false;
      }

      if (fileObj) {
        const img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          const width = img.naturalWidth,
            height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched(name, true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObj({
              ...imageObj,
              [name]: fileObj,
            });
            setValue(name, URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().subtract(1, "days");
  };

  return (
    <InsurenceWrapper>
      <RegistrationLayout>
        <div className="flex pt-1 justify-center">
          <div
            className={clsx(
              "flex flex-custom-1 justify-center ptb-8 align-start flex-y pa-12",
              isLg && "pa-32 pr-96"
            )}
          >
            <div className="d-flex justify-content-start">
              <h1 className="Glacial-Bold jumbotrons ">
                {t("THANKS_REG_LABEL")}
              </h1>
            </div>

            <div className="Glacial-Regular fs-16 pt-25 light-grey">
              {t("TELL_US_LABEL")}
            </div>
            <div className="mtp-5 fill-width">
              {/* <label className="fs-16 Glacial-Regular">{t("ZIP_CODE")}</label> */}
              <ZipInput
                lable={t("ZIP_CODE")}
                type="text"
                placeholder={t("ZIP_CODE")}
                value={values.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                id="zipCode"
              ></ZipInput>
              <Error field="zipCode" />
            </div>
            <div className="mtp-5 fill-width">
              <label className="fs-16 Glacial-Regular">
                {t("INSUARANCE_CARRIER")}
              </label>
              <Input
                type="select"
                name="insurance"
                id="insurance"
                onChange={handleChange}
                value={values.insurance}
                onBlur={handleBlur}
              >
                <option value="">{t("SELECT_ONE_LABEL")}</option>
                {!props?.location?.state?.providerCode && (
                  <option value="0">{t("DONT_HAVE_INSURANCE")}</option>
                )}
                {InsurenceData.map((type, i) => {
                  return (
                    <Fragment>
                      <option key={`option${i}`} value={type.id}>
                        {type.name}
                      </option>
                    </Fragment>
                  );
                })}
              </Input>
              <Error field="insurance" />
            </div>
            {values?.insurance !== "" &&
              values?.insurance !== "0" &&
              values?.insurance !== SELF_PAY_INSURANCE_ID &&
              props?.location?.state?.providerType === "ibo" && (
                <>
                  <div className="Glacial-Regular fs-16 pt-25 light-grey">
                    {t("SELECTING_INSURANCE_TEXT")}
                  </div>

                  <div className="mtp-5 fill-width">
                    <label className="fs-16 Glacial-Regular">
                      {t("MEMBER_ID")}
                    </label>
                    <Input
                      lable={t("MEMBER_ID")}
                      type="text"
                      placeholder={t("MEMBER_ID")}
                      value={values.memberId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="memberId"
                    ></Input>
                    <Error field="memberId" />
                  </div>
                  <div className="mtp-5 fill-width">
                    <label className="fs-16 Glacial-Regular">
                      {t("GROUP_ID")}
                    </label>
                    <Input
                      lable={t("GROUP_ID")}
                      type="text"
                      placeholder={t("GROUP_ID")}
                      value={values.groupId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="groupId"
                    ></Input>
                    <Error field="groupId" />
                  </div>
                  <div className="mtp-5 fill-width">
                    <label className="fs-16 Glacial-Regular">
                      {t("PRIMARY_SUB")}
                    </label>
                    <Input
                      type="select"
                      name="primarySubs"
                      id="primarySubs"
                      onChange={(e) => {
                        if (e.target.value === "Self") {
                          setFieldValue("psFirstName", "");
                          setFieldValue("psLastName", "");
                          setFieldValue("psDOB", null);
                        }
                        handleChange(e);
                      }}
                      value={values.primarySubs}
                      onBlur={handleBlur}
                    >
                      <option value="">{t("SELECT_ONE_LABEL")}</option>
                      <option value="Self">{t("SELF")}</option>
                      <option value="Dependent">{t("DEPENDENT")}</option>
                      <option value="Spouse">{t("SPOUSE")}</option>
                    </Input>
                    <Error field="primarySubs" />
                  </div>
                  {values?.primarySubs && values?.primarySubs !== "Self" && (
                    <>
                      <div className="mtp-5 fill-width">
                        <label className="fs-16 Glacial-Regular">
                          {t("PRIMARY_SUB_FN")}
                        </label>
                        <Input
                          lable={t("PRIMARY_SUB_FN")}
                          type="text"
                          placeholder={t("PRIMARY_SUB_FN")}
                          value={values.psFirstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="psFirstName"
                        ></Input>
                        <Error field="psFirstName" />
                      </div>
                      <div className="mtp-5 fill-width">
                        <label className="fs-16 Glacial-Regular">
                          {t("PRIMARY_SUB_LN")}
                        </label>
                        <Input
                          lable={t("PRIMARY_SUB_LN")}
                          type="text"
                          placeholder={t("PRIMARY_SUB_LN")}
                          value={values.psLastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="psLastName"
                        ></Input>
                        <Error field="psLastName" />
                      </div>
                      <div className="mtp-5 fill-width">
                        <label className="fs-16 Glacial-Regular">
                          {t("PRIMARY_SUB_DOB")}
                        </label>
                        <DatePicker
                          name="psDOB"
                          selected={new Date()}
                          onChange={(val) => {
                            setFieldTouched("psDOB", true, true);
                            setFieldValue("psDOB", val);
                          }}
                          dateFormat="MM/DD/YYYY"
                          className="custom-datepicker fill-width"
                          calendarClassName="custom-calender-class"
                          disabledDate={(d) => disabledDate(d)}
                          inputReadOnly
                        />
                        <Error field="psDOB" />
                      </div>
                    </>
                  )}

                  <div className="Glacial-Regular fs-16 pt-16 light-grey">
                    {t("UPLOAD_INSURANCE_TEXT")}
                  </div>

                  <div
                    className={clsx(
                      "flex fill-width",
                      isMobile ? "pa-4" : "pa-8"
                    )}
                  >
                    <input
                      ref={frontRef}
                      id="front_insurance_image"
                      className="file-upload__input"
                      name="front_insurance_image"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFrontImage(
                          e,
                          setFieldValue,
                          "front_insurance_image"
                        );
                        setFieldTouched("front_insurance_image", true, true);
                      }}
                      onBlur={handleBlur}
                      required={true}
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/heif"
                    />
                    <input
                      ref={backRef}
                      id="back_insurance_image"
                      className="file-upload__input"
                      name="back_insurance_image"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFrontImage(
                          e,
                          setFieldValue,
                          "back_insurance_image"
                        );
                        setFieldTouched("back_insurance_image", true, true);
                      }}
                      onBlur={handleBlur}
                      required={true}
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/heif"
                    />
                    <div
                      className={clsx(
                        "flex flex-1 flex-y",
                        isMobile || isTbLg ? "pr-6" : "plr-12"
                      )}
                    >
                      <div
                        className={clsx(
                          "pos-relative border-radius-12 fill-width insurance-img-label-bg",
                          isMobile
                            ? "h-200"
                            : isTabletOrMobile
                            ? "h-260"
                            : isTbLg
                            ? "h-180"
                            : "h-240"
                        )}
                      >
                        <div
                          role="button"
                          title="Remove"
                          className="pos-absolute top-2 right-2 cursor-pointer"
                          onClick={() => {
                            if (values?.front_insurance_image) {
                              setFieldValue("front_insurance_image", null);
                              setImageObj({
                                ...imageObj,
                                front_insurance_image: null,
                              });
                            }
                          }}
                        >
                          <DeleteIcon height={24} width={24} fill="#fff" />
                        </div>
                        <div
                          className={clsx(
                            "flex flex-custom-1 border-radius-12 fill-width cursor-pointer box-shadow-insurance",
                            isMobile
                              ? "h-160"
                              : isTabletOrMobile
                              ? "h-220"
                              : isTbLg
                              ? "h-140"
                              : "h-200"
                          )}
                          onClick={() => frontRef.current.click()}
                        >
                          {" "}
                          <img
                            src={
                              values?.front_insurance_image
                                ? values?.front_insurance_image
                                : imagePlaceholder
                            }
                            height={"100%"}
                            width={"100%"}
                            className="border-radius-12"
                          />
                        </div>
                        <div className="text-center flex flex-custom-1 h-40 justify-center align-center">
                          <span className="Glacial-Regular fs-16">
                            {t("FRONT")}
                          </span>
                        </div>
                      </div>
                      <Error field="front_insurance_image" />
                    </div>

                    <div
                      className={clsx(
                        "flex flex-1 flex-y",
                        isMobile || isTbLg ? "pl-6" : "plr-12"
                      )}
                    >
                      <div
                        className={clsx(
                          "pos-relative border-radius-12 fill-width insurance-img-label-bg",
                          isMobile
                            ? "h-200"
                            : isTabletOrMobile
                            ? "h-260"
                            : isTbLg
                            ? "h-180"
                            : "h-240"
                        )}
                      >
                        <div
                          role="button"
                          title="Remove"
                          className="pos-absolute top-2 right-2 cursor-pointer"
                          onClick={() => {
                            if (values?.back_insurance_image) {
                              setFieldValue("back_insurance_image", null);
                              setImageObj({
                                ...imageObj,
                                back_insurance_image: null,
                              });
                            }
                          }}
                        >
                          <DeleteIcon height={24} width={24} fill="#fff" />
                        </div>
                        <div
                          className={clsx(
                            "flex flex-custom-1 border-radius-12 fill-width cursor-pointer box-shadow-insurance",
                            isMobile
                              ? "h-160"
                              : isTabletOrMobile
                              ? "h-220"
                              : isTbLg
                              ? "h-140"
                              : "h-200"
                          )}
                          onClick={() => backRef.current.click()}
                        >
                          <img
                            src={
                              values?.back_insurance_image
                                ? values?.back_insurance_image
                                : imagePlaceholder
                            }
                            height={"100%"}
                            width={"100%"}
                            className="border-radius-12"
                          />
                        </div>
                        <div className="text-center flex flex-custom-1 h-40 justify-center align-center">
                          <span className="Glacial-Regular fs-16">
                            {t("BACK")}
                          </span>
                        </div>
                      </div>
                      <Error field="back_insurance_image" />
                    </div>
                  </div>
                </>
              )}

            <div className="mtp-5 fill-width">
              <label className="fs-16 Glacial-Regular">
                {t("CATEGORY_IMP_LABEL")}
              </label>
            </div>
            <div
              className="d-flex justify-content-between mt-3 fill-width"
              id="category"
            >
              {CategoryListArray.map((res) => {
                return (
                  <div
                    className={
                      "box cursor-pointer" +
                      (values.category_id === res.id ? " green-border" : "")
                    }
                    onClick={(e) => {
                      setFieldValue("category_id", res.id);
                    }}
                  >
                    <div className="position-relative">
                      <img
                        className="box-image  "
                        src={res.image}
                        alt={res.name}
                      ></img>
                      <span className="position-absolute icon right-5">
                        <img
                          className="icon-opecity icon-res "
                          src={QuestionImage}
                          alt="Question mark"
                          onClick={(e) => setQuestionPopup(true)}
                        ></img>
                        {QuestionPopup && values.category_id === res.id && (
                          <div className="plrp-10">
                            <div className="position-absolute  popup white--text Glacial-Regular  d-flex align-items-center">
                              {t(res.info)}
                              <div
                                className="popup-place cursor-pointer"
                                onClick={(e) => setQuestionPopup(false)}
                              >
                                <img src={CloseImage} alt="close"></img>
                              </div>
                            </div>
                          </div>
                        )}
                      </span>
                      <div className="Glacial-Bold font-weight-bold pt-3">
                        {t(res.name)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Error field="category_id" />
            <div className="fs-16 Glacial-Regular mtp-10 fill-width">
              <input
                type="checkbox"
                id="isCheckedAcknowledgements"
                name="isCheckedAcknowledgements"
                style={{ marginRight: 4, marginTop: 2 }}
                className="cursor-pointer"
                checked={values?.isCheckedAcknowledgements}
                onChange={(e) => {
                  setFieldTouched("isCheckedAcknowledgements", true, true);
                  setFieldValue(
                    "isCheckedAcknowledgements",
                    !values?.isCheckedAcknowledgements
                  );
                }}
              />
              {t("REGISTRATION_LABEL_SIGNING_AGREE")}{" "}
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(
                    `${process.env.REACT_APP_PUBLIC_URL}/terms`,
                    "_blank"
                  )
                }
              >
                {t("TERMS_AND_CONDITION")}
              </span>
              ,
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(
                    `${process.env.REACT_APP_PUBLIC_URL}/privacy`,
                    "_blank"
                  )
                }
              >
                {" "}
                {t("PRIVACY_POLICY")}
              </span>
              ,
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(
                    `${process.env.REACT_APP_PUBLIC_URL}/hipaa-consent`
                  )
                }
              >
                {" "}
                {t("HIPPA_POLICY")}
              </span>{" "}
              {t("AND")}{" "}
              <span
                className="border-bottom fs-16 Glacial-Regular cursor-pointer"
                onClick={(e) =>
                  window.open(`${process.env.REACT_APP_PUBLIC_URL}/payment`)
                }
              >
                {" Husk "}
                {t("PAYMENT_POLICY")}
              </span>
              .
            </div>
            <Error field="isCheckedAcknowledgements" />
            <div className="mbp-5 fill-width">
              <Button
                text={t("SUBMIT_BUTTON")}
                onClick={(e) => onSubmitOfInsurence(e)}
                loading={Loading}
              ></Button>
            </div>
          </div>
        </div>
      </RegistrationLayout>
    </InsurenceWrapper>
  );
};

export default compose(
  withRouter,
  enhancer,
  connect(null, { login })
)(Insurence);
