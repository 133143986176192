import React from "react";
import { useMedia } from "../../hook/useMedia";
import { LanguageSelector } from "../LanguageSelector";
import clsx from "clsx";
import loginImage from "../../assets/images/nutrition1.png";
import LOGO from "../../assets/images/huskLogo.png";

export const RegistrationLayout = ({ children }) => {
  const { isLg, isMd } = useMedia();

  return (
    <div
      className={clsx(
        "fill-width max-full-width flex pos-relative",
        isLg ? "flex-x overflow-hide full-height" : "flex-y overflow-auto"
      )}
    >
      {!isLg && (
        <div className="pos-absolute right-px-12 top-px-4">
          <LanguageSelector />
        </div>
      )}
      <div
        className={clsx(
          "flex-y flex ",
          isMd && "flex flex-custom-1",
          isLg && "flex-custom-3"
        )}
      >
        <div className="flex ptb-6">
          <div className="flex pl-20">
            <img src={LOGO} height={58} width={124} />
          </div>
        </div>
        <div className="flex flex-custom-1 justify-center align-center">
          <img
            src={loginImage}
            height={"100%"}
            width={"100%"}
            className="object-contain"
            alt="nutrition"
          />
        </div>
        <div className={clsx(isLg ? "h-8vh" : "h-0")}></div>
      </div>
      <div
        className={clsx(
          "flex flex-y flex-custom-1 fill-width overflow-x-auto pos-relative",
          isLg && "flex-custom-2 overflow-x-hidden"
        )}
      >
        <>
          {isLg && (
            <div className="pos-absolute right-px-12 top-px-4">
              <LanguageSelector />
            </div>
          )}
          {children}
        </>
      </div>
    </div>
  );
};
