import styled from "styled-components";

const FilterWrapper = styled.div`
  width: ${(props) => (props.isMobile ? "100vw" : "332px")};
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  color: #fff;
  background-color: #aece38;

  .filter-container {
    height: 92vh;
    padding-inline: 28px;
    overflow-y: auto;
  }
  .button-container {
    height: 8vh;
    padding-inline: 28px;
    padding-top: 6px;
  }
`;

export default FilterWrapper;
