import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store, history, persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Register from "../components/Register/Register";
import Insurence from "../components/Insurence/Insurence";
import Schedule from "../components/Schedule/Schedule";
import StaticPage from "../components/StaticPage/StaticPage";
import Calendly from "../components/Calendly/Calendly";
import Slots from "../components/Slots/Slots";
import RDList from "../components/RDList/RDList";
import ScheduleAppointment from "../components/ScheduleAppointment/ScheduleAppointment";
import AppointmentConfirmation from "../components/AppointmentConfirmation/AppointmentConfirmation";
import BookMobileAppointment from "../components/BookMobileAppointment/BookMobileAppointment";
import Preference from "../components/Preference/Preference";
import DiscoveryQueue from "../components/DiscoveryQueue/DiscoveryQueue";

const routes = (props) => {
  window.addEventListener("popstate", () => {
    history.go(1);
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/register" />} />
            <Route path="/register" component={Register} />
            <Route path="/insurance" component={Insurence} />
            <Route path="/terms" component={StaticPage} />
            <Route path="/privacy" component={StaticPage} />
            <Route path="/hipaa-consent" component={StaticPage} />
            <Route
              exact
              path="/hippa-consent"
              render={() => <Redirect to="/hipaa-consent" />}
            />
            <Route path="/payment" component={StaticPage} />
            <Route
              path="/book-mobile-appointment"
              component={BookMobileAppointment}
            />
            <Route path="/schedule" component={Schedule} />
            <Route path="/calendly" component={Calendly} />
            <Route path="/rd-slots" component={Slots} />
            <Route path="/rd-list" component={RDList} />
            <Route
              path="/schedule-appointment"
              component={ScheduleAppointment}
            />
            <Route
              path="/appointment-confirmation"
              component={AppointmentConfirmation}
            />
            <Route path="/preference" component={Preference} />
            <Route path="/discovery-queue" component={DiscoveryQueue} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};
export default routes;
