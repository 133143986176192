import styled from "styled-components";

const LandingPageWrapper = styled.div`
  .set-image {
    height: 100vh;
  }
  input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid rgba(12, 11, 11, 0.2);
    /* border-color: #0C0B0B; */
    width: 100%;
    font-size: 18px;
    line-height: 32px;
    color: #5A6B80;
  }

  .ui-underline {
    border: none;
    border-bottom: 1px solid #bbb;
    outline: none;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    color:#5A6B80;
  }

  .popup {
    background-color: #58585a;
    width: 350px;
    height: 100px;
    top: -75px;
    border-radius: 10px;
  }
  .popup-place {
    width: 20%;
    height: 27%;
    background-color: white;
    border-radius:50%;
    text-align: center;
  }

  .popup-place-2-line {
    width: 12%;
    height: 27%;
    background-color: white;
    border-radius:50%;
    text-align: center;
  }

  .ui-underline::placeholder {
    font-weight: normal;
    color:#a2a3af;
  }

  .ui-form-white,
  .ui-form-white .ui-placeholder span {
    color: #fff;
  }

  .ui-form-white input::placeholder,
  .ui-form-white select::placeholder,
  .ui-form-white textarea::placeholder {
    color: #fff;
    opacity: 0.5;
  }

  .ui-form-white input,
  select,
  .ui-form-white textarea {
    border-bottom-color: #fff;
  }

  .ui-form-white .ui-underline {
    color: #fff;
  }

  .ui-form-black,
  .ui-form-black .ui-placeholder span {
    color: #000;
  }

  .ui-form-black input::placeholder,
  .ui-form-black select::placeholder,
  .ui-form-black textarea::placeholder {
    color: #000;
    opacity: 0.5;
  }

  .ui-form-black input,
  .ui-form-black select,
  .ui-form-black textarea {
    border-bottom-color: #000;
  }

  .ui-form-black .ui-underline {
    color: #000;
  }

  .ui-placeholder {
    position: relative;
    perspective: 100px;
  }

  .ui-placeholder input,
  .ui-placeholder select,
  .ui-placeholder textarea {
    width: 100%;
    background-color: transparent;
  }

  .ui-placeholder input ~ span,
  .ui-placeholder select ~ span,
  .ui-placeholder textarea ~ span {
    visibility: visible;
    position: absolute;
    opacity: 0;
    display: inline-block;
    transition: all 1s cubic-bezier(0.08, 0.86, 0.35, 0.94);
    font-size: 14px;

    color: #999;
  }

  .ui-placeholder input:not(:placeholder-shown) ~ span,
  .ui-placeholder select:not(:placeholder-shown) ~ span,
  .ui-placeholder textarea:not(:placeholder-shown) ~ span {
    visibility: visible;
    opacity: 0;
  }

  /* perspective origin */
  .ui-placeholder.ui-leftTop {
    perspective-origin: left top;
  }

  .ui-placeholder.ui-leftTopCorner {
    perspective-origin: left top;
  }

  /* set position */
  .ui-placeholder.ui-leftTop input ~ span,
  .ui-placeholder.ui-leftTop select ~ span,
  .ui-placeholder.ui-leftTop textarea ~ span {
    bottom: 65%;
    left: 0;
  }

  .ui-placeholder.ui-leftTopCorner input ~ span,
  .ui-placeholder.ui-leftTopCorner select ~ span,
  .ui-placeholder.ui-leftTopCorner textarea ~ span {
    right: calc(100% + 5px);
    top: 0;
    width: 200px;
    text-align: right;
  }

  .ui-placeholder.ui-rightTopCorner input ~ span,
  .ui-placeholder.ui-rightTopCorner select ~ span,
  .ui-placeholder.ui-rightTopCorner textarea ~ span {
    left: calc(100% + 5px);
    top: 0;
    width: 200px;
    text-align: left;
  }

  .ui-placeholder.ui-leftTop.ui-slide textarea ~ span,
 
.ui-placeholder.ui-leftTop.ui-slide input ~ span,
 

 

.ui-placeholder.ui-leftTopCorner.ui-slide textarea ~ span,
 
.ui-placeholder.ui-leftTopCorner.ui-slide input ~ span,
 

.ui-placeholder.ui-rightTopCorner.ui-slide textarea ~ span,
 
.ui-placeholder.ui-rightTopCorner.ui-slide input ~ span,


.ui-placeholder.ui-leftTop.ui-slide textarea:not(:placeholder-shown) ~ span,
 
.ui-placeholder.ui-leftTop.ui-slide input:not(:placeholder-shown) ~ span,
 

.ui-placeholder.ui-leftBottom.ui-slide textarea:not(:placeholder-shown) ~ span,
 

.ui-placeholder.ui-leftTopCorner.ui-slide textarea:not(:placeholder-shown) ~ span,
 
.ui-placeholder.ui-leftTopCorner.ui-slide input:not(:placeholder-shown) ~ span,
 

 
 
 
/* demo css just for formating demo */
body {
    background-color: dodgerBlue;
  }

  .row > div {
    margin-bottom: 50px;
  }

  .wrapper h1 {
    padding: 20px;
    text-align: center;
    padding-bottom: 50px;
  }
`;

export default LandingPageWrapper;
