import React from "react";

import StyledButtonWrapper from "./style";
import { Spin } from "antd";

export const StyledButton = ({ ...props }) => {
  const RightIcon = props?.iconRight ?? null;
  const LeftIcon = props?.iconLeft ?? null;
  return (
    <StyledButtonWrapper {...props}>
      <div className="flex justify-content-center align-items-center">
        {!!props.iconLeft && (
          <div className="pr-1">
            <LeftIcon className="fillStroke h-16 w-16 " />
          </div>
        )}
        <div>{props.children}</div>
        {!!props.iconRight && (
          <div className="pl-1">
            <RightIcon className="fillStroke h-16 w-16 rotate-180" />
          </div>
        )}
        {!!props?.loading ? (
          <div className="pl-2">
            <Spin className="color-blue" size="small" />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    </StyledButtonWrapper>
  );
};
