import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    isIbo: Yup.boolean().nullable(),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, "ONLY_DIGITS_VALIDATION")
      .min(5, "ZIP_FIVE_CHAR_VALIDATION")
      .max(5, "ZIP_FIVE_CHAR_VALIDATION")
      .required("ZIP_CODE_VALIDATION"),
    category_id: Yup.string().required("CHOOSE_CAT_VALIDATION"),
    insurance: Yup.string().required("SELECT_INSURANCE_VALIDATION"),
    isCheckedAcknowledgements: Yup.boolean()
      .oneOf([true], "ACK_VALIDATION")
      .required("ACK_VALIDATION"),
    groupId: Yup.string().notRequired().nullable(),
    memberId: Yup.string().when("isIbo", {
      is: (val) => !val,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("SELECT_MEMBERID_VALIDATION"),
    }),
    primarySubs: Yup.string().when("isIbo", {
      is: (val) => !val,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("SELECT_PRIMARY_SUB_VALIDATION"),
    }),
    psFirstName: Yup.string().when("primarySubs", {
      is: (val) => !val || val === "Self",
      then: Yup.string().notRequired(),
      otherwise: Yup.string()
        .trim("SPACE_VALIDATION")
        .strict(true)
        .matches(/^[A-Za-z0-9 ]+$/, "SPECIAL_CHAR_VALIDATION")
        .required("SELECT_PRIMARY_SUB_FN_VALIDATION"),
    }),
    psLastName: Yup.string().when("primarySubs", {
      is: (val) => !val || val === "Self",
      then: Yup.string().notRequired(),
      otherwise: Yup.string()
        .trim("SPACE_VALIDATION")
        .strict(true)
        .matches(/^[A-Za-z0-9 ]+$/, "SPECIAL_CHAR_VALIDATION")
        .required("SELECT_PRIMARY_SUB_LN_VALIDATION"),
    }),
    psDOB: Yup.string().when("primarySubs", {
      is: (val) => !val || val === "Self",
      then: Yup.string().notRequired().nullable(),
      otherwise: Yup.string()
        .required("SELECT_PRIMARY_SUB_DOB_VALIDATION")
        .nullable(),
    }),
    front_insurance_image: Yup.string().when("isIbo", {
      is: (val) => !val,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("UPLOAD_FRONT_INSURANCE_VALIDATION"),
    }),
    back_insurance_image: Yup.string().when("isIbo", {
      is: (val) => !val,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("UPLOAD_BACK_INSURANCE_VALIDATION"),
    }),
  }),
  handleSubmit: (values) => {},
  mapPropsToValues: (props) => ({
    insurance: "",
    isIbo: props?.location?.state?.providerType === "ibo",
  }),
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: false,
});

export default formikEnhancer;
