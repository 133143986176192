import React, { useState } from "react";
import InputWrapper from "./Input.style";
import { withRouter } from "react-router-dom";
import QuestionImage from "../../assets/images/Vector.jpg";
import CloseImage from "../../assets/images/Close.svg";
import { useTranslation } from "react-i18next";

const Input = (props) => {
  const { t } = useTranslation();

  const [Active, setActive] = useState(false);
  const [QuestionPopup, setQuestionPopup] = useState(false);

  return (
    <InputWrapper>
      <div className=" ui-placeholder ui-leftTop">
        {/* {props.isRegisterationCode && (
          <span>
            <img
              className="ml-1 pb-1"
              src={QuestionImage}
              alt="Registration info"
            ></img>
          </span>
        )} */}
        <br></br>
        <div className={Active || props.value ? "visible" : "invisible"}>
          <span className="Glacial-Regular fs-18">{props.placeholder}</span>
          {props.isRegisterationCode && (
            <span>
              <img
                className="ml-1 pb-1 icon-opecity position-relative cursor-pointer"
                src={QuestionImage}
                alt="Registration info"
                onClick={(e) => setQuestionPopup(true)}
                onMouseOver={(e) => setQuestionPopup(true)}
              ></img>
              {QuestionPopup && (
                <div>
                  <div className="position-absolute  right-11 popup white--text Glacial-Regular plrp-5 d-flex align-items-center">
                    {t("REGISTRATION_TOOLTIP")}
                    <div
                      className="popup-place cursor-pointer"
                      onClick={(e) => setQuestionPopup(false)}
                    >
                      <img src={CloseImage} alt="close"></img>
                    </div>
                  </div>
                </div>
              )}
            </span>
          )}
          {props.isFirstName && (
            <span>
              <img
                className="ml-1 pb-1 icon-opecity position-relative cursor-pointer"
                src={QuestionImage}
                alt="Registration info"
                onClick={(e) => setQuestionPopup(true)}
                onMouseOver={(e) => setQuestionPopup(true)}
              ></img>
              {QuestionPopup && (
                <div>
                  <div className="position-absolute  right-11 popup white--text Glacial-Regular plrp-5 d-flex align-items-center">
                    {t("FIRST_NAME_TOOLTIP")}
                    <div
                      className="popup-place-2-line cursor-pointer"
                      onClick={(e) => setQuestionPopup(false)}
                    >
                      <img src={CloseImage} alt="close"></img>
                    </div>
                  </div>
                </div>
              )}
            </span>
          )}
          {props.isLastName && (
            <span>
              <img
                className="ml-1 pb-1 icon-opecity position-relative cursor-pointer"
                src={QuestionImage}
                alt="Registration info"
                onClick={(e) => setQuestionPopup(true)}
                onMouseOver={(e) => setQuestionPopup(true)}
              ></img>
              {QuestionPopup && (
                <div>
                  <div className="position-absolute  right-11 popup white--text Glacial-Regular plrp-5 d-flex align-items-center">
                    {t("LAST_NAME_TOOLTIP")}
                    <div
                      className="popup-place-2-line cursor-pointer"
                      onClick={(e) => setQuestionPopup(false)}
                    >
                      <img src={CloseImage} alt="close"></img>
                    </div>
                  </div>
                </div>
              )}
            </span>
          )}
        </div>
        <input
          id={props.id}
          value={props.value}
          className={
            props.className
              ? `${props.className} Glacial-Regular input ui-underline`
              : "Glacial-Regular input ui-underline"
          }
          type={props.type}
          placeholder={Active || props.value ? "" : props.placeholder}
          onFocus={(e) => setActive(true)}
          onChange={props.onChange}
          disabled={props.disabled}
          onBlur={props.onBlur}
        ></input>
      </div>
    </InputWrapper>
  );
};

export default withRouter(Input);
