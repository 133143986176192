import React, { useState, useEffect } from "react";
import InsurenceWrapper from "./StaticPage.style";
import AuthenticationApiSerivce from "../../api/AuthServices";
import { message } from "antd";

const StaticPage = (props) => {
  const [Data, setData] = useState({});

  useEffect(() => {
    let params = {
      type:
        props.location.pathname === "/terms"
          ? "terms"
          : props.location.pathname === "/privacy"
          ? "privacy"
          : props.location.pathname === "/hipaa-consent"
          ? "hippa"
          : props.location.pathname === "/payment"
          ? "payment"
          : "",
    };
    AuthenticationApiSerivce.staticPages(params)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
      });
  }, []);

  return (
    <InsurenceWrapper>
      <div className="plrp-8 ptbp-5 ">
        <div className="row ma-0 pa-0">
          <div className="fs-40 Glacial-Bold">{Data.title}</div>
        </div>
        <div>
          <div
            className="mtp-1 fs-20 Glacial-Regular light-gray"
            dangerouslySetInnerHTML={{ __html: Data.content }}
          ></div>
        </div>
        {/* <Button width = "20%" text="BACK" onClick={}/>  */}
      </div>
    </InsurenceWrapper>
  );
};

export default StaticPage;
