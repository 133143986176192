import styled from "styled-components";

const SlotsPageWrapper = styled.div`
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .set-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
    /* min-height: 100vh; */
    /* object-position: left; */
    @media (max-width: 1024px) {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    @media (min-width: 1025px) {
      min-height: 100vh;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* @media (min-width: 1500px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 15% !important;
    } */
  }
  .code {
    position: absolute;
    bottom: 12%;
    right: 0;
    border-bottom: 1px solid #5a6b80;
    line-height: 1;
    color: #5a6b80;
  }
  .jumbotrons {
    /* padding-top: 7%; */
    color: #5A6B80;
    font-size: 3.5rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 2.3rem;
    }
  }
  .app-icon {
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
      padding-bottom: 20%;
    }
  }
  .logo {
    top: 30px;
    left: 25%;
    @media (max-width: 1024px) {
      top: 15px;
      left: 40%;
    }
  }

  .close {
    position: absolute;
    bottom: 5%;
    right: 0;
    font-size: 25px;
    padding-right: 2%;
  }
  .text-danger {
    color: #e06226 !important;
  }
`;

export default SlotsPageWrapper;
