import { Spin } from "antd";
import React from "react";
import "./Button.css";

export default function Button(props) {
  let { height, width, text, onClick, id, loading, marginTop, disabled } =
    props;
  return (
    <div>
      <button
        style={{
          height: height ? height : "55px",
          width: width ? width : "100%",
          border: "none",
          borderRadius: "12px",
          backgroundColor: !disabled ? "#AECE38" : "#eee",
          color: "white",
          fontFamily: "Glacial-Regular",
          fontWeight: "bolder",
          marginTop: marginTop ? marginTop : "50px",
          letterSpacing: "2px",
        }}
        id={id}
        onClick={onClick}
        disabled={loading || disabled}
      >
        {text} {loading && `  `}
        {loading ? <Spin style={{ color: "blue" }} size="small" /> : ""}
      </button>
    </div>
  );
}
