import authAction from "./actions";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  accessToken: localStorage.getItem("accessToken"),
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  image: null,
  state: null,
  insurance: null,
  isInDiscovery: false,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: action.accessToken,
        userId: action.userId,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        state: action.state,
        insurance: action.insurance,
        isInDiscovery: false,
      };
    case "USER_DATA":
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        Image: action.Image,
        user_data: action.user_data,
      };

    case authAction.LOGOUT:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: null,
      };

    case "DISCOVERY":
      return {
        ...state,
        isInDiscovery: true,
      };
    default:
      return state;
  }
}
