export const appLogo = require("../assets/images/logo.png");

export const Url = process.env.REACT_APP_BASE_URL;
export const ENCRYPTKEY = "itstopsecretrequest";
export const googleUrl = process.env.REACT_APP_GOOGLE_PLAY_URL;
export const appleUrl = process.env.REACT_APP_APPLE_URL;

const size = {
  mobileS: "321px",
  mobileM: "376px",
  mobileL: "426px",
  tablet: "767px",
  laptop: "1025px",
  laptopL: "1441px",
  desktop: "2561px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const PREFERENCE_TYPE = {
  BY_TIME: "TIME",
  ALL: "ALL",
};

export const INIT_FILTERS = {
  speciality: [],
  language: [],
  timezone: null,
};

export const INPERSON_VISIT_TYPE = "initial-in-person";

export const SELF_PAY_INSURANCE_ID =
  process.env.REACT_APP_SELF_PAY_INSURANCE_ID;
