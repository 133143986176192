import React from "react";

export const FilterIcon = (props) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g data-name="66-Option">
        <path d="M8 9H7V0H5v9H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v17h2V15h1a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zM18 19h-1V0h-2v19h-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v7h2v-7h1a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zM28 7h-1V0h-2v7h-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v19h2V13h1a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1z" />
      </g>
    </svg>
  );
};
