import clsx from "clsx";
import React, { useState } from "react";
import { useMedia } from "../../hook/useMedia";
import { PREFERENCE_TYPE } from "../../helper/constants";
import { StyledButton } from "../common/StyledButton";
import ProfileImage from "../../assets/images/userPlaceholder.png";
import moment from "moment";
import { truncateString } from "../../helper/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const RDCard = ({ rd, length, index, handleBook, type }) => {
  const {
    isTabletOrMobile,
    isDesktopOrLaptop,
    isMobile,
    isAfterTablet,
    isAfterDesktop,
    isTabletTilt,
  } = useMedia();
  const { t } = useTranslation();

  const [isMore, setIsMore] = useState(false);

  return (
    <div
      className={clsx(
        "flex justify-center ptb-16",
        length % 2 !== 0 &&
          index + 1 === length &&
          !isTabletOrMobile &&
          "grid-span-1",
        index % 2 === 0 && isDesktopOrLaptop
          ? "pr-16"
          : isDesktopOrLaptop && "pl-16"
      )}
    >
      <div
        className={clsx(
          "border border-gray border-radius-24 flex flex-y align-center flex-100 pa-24",
          length % 2 !== 0 &&
            index + 1 === length &&
            !isAfterDesktop &&
            "flex-50",
          !isTabletOrMobile && "pa-45"
        )}
      >
        <div className="border-round h-142 w-142">
          <img
            src={rd?.profile_image ?? ProfileImage}
            height={"100%"}
            width={"100%"}
            className="border-round"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="text-center font-bold fs-24 pt-9 color-dark">
          {rd?.first_name} {rd?.last_name}
        </div>
        {type === PREFERENCE_TYPE.ALL && (
          <div
            className={clsx(
              "flex color-orange fs-16 flex-y justify-center align-center pt-11 font-bold",
              !isMobile && "fs-18"
            )}
          >
            <div className="flex justify-center align-center flex-wrap ">
              {t("NEXT_AVAIL_APPT")}:
            </div>
            <div className="flex justify-center align-center flex-wrap text-center">
              {moment
                .tz(rd?.nextSlotTime?.start_time, moment.tz.guess())
                .format("LLLL")}
            </div>
          </div>
        )}
        {isDesktopOrLaptop && (
          <div
            className={clsx(
              "text-center pt-4 hidden",
              isDesktopOrLaptop && "flex"
            )}
            style={{
              height: isMore ? "auto" : isTabletTilt ? "280px" : "190px",
              minHeight: isTabletTilt ? "280px" : "190px",
            }}
          >
            <div className="text-textGray  font-glacial">
              {isMore ? (
                <span>
                  {rd?.rd_detail?.profile_text}{" "}
                  <span
                    className="underlineText cursor-pointer"
                    onClick={() => setIsMore(false)}
                  >
                    {t("SEE_LESS")}
                  </span>
                </span>
              ) : (
                truncateString(rd?.rd_detail?.profile_text, 400)
              )}
              {!isMore && rd?.rd_detail?.profile_text?.length > 400 && (
                <span
                  className="underlineText cursor-pointer"
                  onClick={() => setIsMore(true)}
                >
                  {t("SEE_MORE")}
                </span>
              )}
            </div>
          </div>
        )}
        {!isDesktopOrLaptop && (
          <div className="text-center pt-4 h-auto lg:hidden">
            <div className="text-textGray  font-glacial">
              {rd?.rd_detail?.profile_text}
            </div>
          </div>
        )}
        <div className="flex justify-center align-center flex-wrap pt-11 mt-1">
          <div
            className={clsx(
              "border border-radius-16 mlr-4 plr-12 ptb-4 text-center",
              `border-${rd?.rd_detail?.primary_category_id}`
            )}
          >
            {rd?.rd_detail?.primary_category?.name}
          </div>
          <div
            className={clsx(
              "border border-radius-16 mlr-4 plr-12 ptb-4 text-center mt-1",
              `border-${rd?.rd_detail?.secondary_category_id}`,
              isAfterTablet && "mt-1"
            )}
          >
            {rd?.rd_detail?.secondary_category?.name}
          </div>
        </div>
        {type === PREFERENCE_TYPE.ALL && (
          <>
            {!!rd?.languages?.length && (
              <div
                className={clsx(
                  "flex color-orange font-bold fs-16 justify-center align-center flex-wrap pt-11",
                  !isMobile && "fs-18"
                )}
              >
                <div className="flex flex-x align-items-center">
                  <div style={{ paddingRight: 5 }}>
                    {t("LANGUAGE_RD_CARD")}:
                  </div>
                  {rd?.languages.map((language, index) => (
                    <>
                      <div>
                        {language?.name === "Espanol"
                          ? "Español"
                          : language?.name}
                      </div>
                      {index !== rd?.languages?.length - 1 && <div> {","}</div>}
                    </>
                  ))}
                </div>
              </div>
            )}
            {!!rd?.rd_detail?.timezone && (
              <div
                className={clsx(
                  "flex color-orange text-center font-bold fs-16 justify-center align-center flex-wrap",
                  !isMobile && "fs-18"
                )}
              >
                {t("TIME_ZONE_RD_CARD")}: {rd?.rd_detail?.timezone?.name}
              </div>
            )}
          </>
        )}
        <div className="text-center pt-15">
          <StyledButton
            py="8"
            px={type === PREFERENCE_TYPE.ALL ? "16" : "20"}
            borderRadius="12px"
            color="#fff"
            bgColor="#AECE38"
            hoverColor="#AECE38"
            border={"1px solid #AECE38"}
            onClick={(e) => {
              handleBook(rd);
            }}
          >
            {type === PREFERENCE_TYPE.ALL
              ? t("BROWSE_AVAIL_TIME")
              : t("BOOK_NOW_BUTTON")}
          </StyledButton>
        </div>
      </div>
    </div>
  );
};
