import styled from "styled-components";

const DiscoveryQueueWrapper = styled.div`
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default DiscoveryQueueWrapper;
