import React from "react";
import SlotsPageWrapper from "./book_appointment.style";
import { useEffect, useState } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { connect } from "react-redux";
import clsx from "clsx";
import { useMedia } from "../../hook/useMedia";
import Logo from "../../assets/images/CHARGELOGO.png";
import Footer from "../common/Footer/Footer";
const queryString = require("query-string");

const BookMobileAppointment = (props) => {
  const { isTabletOrMobile, isDesktopOrLaptop, isMobile } = useMedia();

  let queryData = queryString.parse(props.location.search);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      window.location.replace("/book-mobile-success");
      // props.history.push("");
      // console.log("===========");
    },
  });

  return (
    <SlotsPageWrapper>
      <div>
        <InlineWidget
          url={queryData?.scheduling_url}
          prefill={{
            email: queryData?.email,
            name: queryData?.first_name + " " + queryData?.last_name,
          }}
          styles={{
            height: "100vh",
          }}
        />
      </div>
      <Footer width={100}></Footer>
    </SlotsPageWrapper>
  );
};

export default BookMobileAppointment;
