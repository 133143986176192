import React, { useEffect, useState } from "react";
import DiscoveryQueueWrapper from "./DiscoveryQueue.style";
import Logo from "../../assets/images/CHARGELOGO.png";
import { useMedia } from "../../hook/useMedia";
import clsx from "clsx";
import Footer from "../common/Footer/Footer";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";
import AuthenticationApiSerivce from "../../api/AuthServices";
import { connect } from "react-redux";
import AuthActions from "../../redux/auth/actions";
import { Loader } from "../../icons";
import Button from "../common/Button/Button";

const { discovery } = AuthActions;

const DiscoveryQueue = (props) => {
  const { isTabletOrMobile } = useMedia();
  const { t } = useTranslation();
  const [isAddingToDF, setIsAddingToDF] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!props?.isInDiscovery) {
      addClientToDiscoveryQueue();
    }
  }, [props?.isInDiscovery]);

  const addClientToDiscoveryQueue = () => {
    setIsAddingToDF(true);
    AuthenticationApiSerivce.addToDiscoveryQueue()
      .then((res) => {
        if (res?.status) {
          props?.discovery();
        } else {
          setIsError(true);
        }
        setIsAddingToDF(false);
      })
      .catch((err) => {
        setIsAddingToDF(false);
        setIsError(true);
      });
  };

  return (
    <DiscoveryQueueWrapper>
      <div className="flex flex-y flex-custom-1 pos-relative">
        <div className="pos-absolute top-px-4 right-px-12">
          <LanguageSelector />
        </div>
        <div className="flex-100 pt-18">
          <div>
            <img src={Logo} height={"100%"} width="100%" alt={"Husk Logo"} />
          </div>
        </div>
        <div className="d-flex flex-y flex-custom-1 mb-64 justify-content-center text-center">
          <div
            className={clsx(
              "Glacial-Bold jumbotrons fs-24 pa-12 text-center",
              !isTabletOrMobile && "fs-34 pa-32"
            )}
          >
            {isAddingToDF ? (
              <Loader height="72px" width="72px"></Loader>
            ) : isError ? (
              <div className="flex flex-y">
                <div
                  className={clsx(
                    "Glacial-Bold jumbotrons fs-24 pa-12 text-center",
                    !isTabletOrMobile && "fs-34 pa-32"
                  )}
                >
                  {t("SOMETHING_WENT_WRONG")}
                </div>
                <Button
                  width={320}
                  text={t("TRY_AGAIN")}
                  onClick={addClientToDiscoveryQueue}
                  loading={isAddingToDF}
                  disabled={isAddingToDF}
                  marginTop="0px"
                ></Button>
              </div>
            ) : (
              <div
                className={clsx(
                  "Glacial-Bold jumbotrons fs-24 pa-12 text-center",
                  !isTabletOrMobile && "fs-34 pa-32"
                )}
              >
                {t("DISCOVERY_MSG")}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer width={100}></Footer>
    </DiscoveryQueueWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

export default connect(mapStateToProps, { discovery })(DiscoveryQueue);
