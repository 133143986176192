import styled from "styled-components";

const StyledButtonWrapper = styled.button`
  color: #fff;
  background-color: #ff7f00;
  font-size: 16px;
  margin-top: ${(props) => props.mt && `${props.mt}px`}; //14px;
  margin-right: ${(props) => props.mr && `${props.mr}px`}; //14px;
  margin-left: ${(props) => props.ml && `${props.ml}px`}; //14px;
  margin-inline: ${(props) => props.mx && `${props.mx}px`};
  margin-block: ${(props) => props.my && `${props.my}px`};
  margin-bottom: ${(props) => props.mt && `${props.mt}px`};
  margin: ${(props) => props.m && `${props.m}`};
  padding-top: ${(props) => props.pt && `${props.pt}px`}; //14px;
  padding-inline: ${(props) => props.px && `${props.px}px`};
  padding-block: ${(props) => props.py && `${props.py}px`};
  padding-bottom: ${(props) => props.pt && `${props.pt}px`};
  padding: ${(props) => props.p && `${props.p}`}; //10px 20px;
  border: ${(props) => props.variant === "contained" && 0};
  border: ${(props) => props.variant === "outlined" && "1px solid #fff"};
  border: ${(props) => props.border && `${props.border}`}; //320px;
  width: ${(props) => props.width && `${props.width}`}; //320px;
  height: ${(props) => props.height && `${props.height}`};
  color: ${(props) => props.color && `${props.color}`};
  border-radius: ${(props) => props.borderRadius && `${props.borderRadius}`};
  background-color: ${(props) => props.bgColor && `${props.bgColor}`};
  background-color: ${(props) => props.variant === "contained" && "#ff7f00"};
  background-color: ${(props) =>
    props.variant === "outlined" && `${props.bgColor}`}; //"transparent"}
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  .fillStroke {
    stroke: ${(props) => `${props.color}`};
  }

  &:hover {
    background-color: transparent;
    border: ${(props) =>
      props.hoverColor
        ? `1px solid ${props.hoverColor}`
        : `1px solid ${props.color}`};
    color: ${(props) =>
      props.hoverColor ? `${props.hoverColor}` : `${props.color}`};

    .fillStroke {
      stroke: ${(props) =>
        props.hoverColor ? `${props.hoverColor}` : `${props.color}`};
    }
  }
`;

// color: ${props => (props.primary ? "white" : "palevioletred")};
// ${props => (props.primary ? "palevioletred" : "white")};
export default StyledButtonWrapper;
