import React, { useState, useEffect } from "react";
import RegisterWrapper from "./Register.style";
import { withRouter } from "react-router-dom";
import Input from "../Input/Input";
import enhancer from "./Register.validator";
import Button from "../../components/common/Button/Button";
import GooglePlayImage from "../../assets/images/GooglePlayBadge.png";
import AppStoreImage from "../../assets/images/AppStoreBadge.png";
import AuthenticationApiSerivce from "../../api/AuthServices";
import { message } from "antd";
import { googleUrl, appleUrl } from "../../helper/constants";
import { useMedia } from "../../hook/useMedia";
import { Loader } from "../../icons";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import TncModal from "../TncModal";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "../../redux/auth/actions";
import clsx from "clsx";
import { RegistrationLayout } from "../RegistrationLayout/RegistrationLayout";

const { login } = AuthActions;

const queryString = require("query-string");
const Register = (props) => {
  const { t } = useTranslation();

  const { isLaptop, isLg, isTabletTilt } = useMedia();
  const [codeLoading, setCodeLoading] = useState(false);
  const [RegistraionCodeDisable, setRegistraionCodeDisable] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [tncModal, setTncModal] = useState(false);
  const [data, setData] = useState(null);

  const WIDTH = !isLaptop ? "wp-55" : "wp-94";

  const {
    setFieldValue,
    setFieldTouched,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
  } = props;

  // const [EmailAvailable, setEmailAvailable] = useState(false);
  useEffect(() => {
    if (props.location.search) {
      let queryData = queryString.parse(props.location.search).code;
      setFieldValue("registrationCode", queryData);
      setRegistraionCodeDisable(true);
    }
    if (JSON.parse(localStorage.getItem("data"))) {
      setFieldValue(
        "registrationCode",
        JSON.parse(localStorage.getItem("data")).registrationCode
      );
      setFieldValue("email", JSON.parse(localStorage.getItem("data")).email);
      setFieldValue(
        "firstName",
        JSON.parse(localStorage.getItem("data")).firstName
      );
      setFieldValue(
        "lastName",
        JSON.parse(localStorage.getItem("data")).lastName
      );
      setFieldValue("registrationCode", "");
    }
  }, []);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={"Glacial-Regular text-danger fs-14 pt-1"}>
          {t(errors[field1])}
        </div>
      );
    } else {
      return <span />;
    }
  };
  // const checkEmail = () => {
  //   console.log("hii");

  //   let param = { email: values.email };
  //   AuthenticationApiSerivce.checkEmail(param).then((res) => {
  //     console.log("🚀 ~ file: Register.jsx ~ line 55 ~ AuthenticationApiSerivce.checkEmail ~ res", res)
  //     if (res.status) {
  //       setEmailAvailable(true);
  //     } else {
  //       message.error(res.message);
  //     }
  //   });
  //   // handleBlur();
  // };
  const getCode = () => {
    setCodeLoading(true);
    AuthenticationApiSerivce.getRegistrationCode()
      .then((res) => {
        if (res.status) {
          setRegistraionCodeDisable(true);
          setFieldValue("registrationCode", res.data.code);
          setCodeLoading(false);
        } else {
          setCodeLoading(false);
          message.error(res.message);
        }
      })
      .catch((err) => {
        setCodeLoading(false);
        message.error("Something went wrong! Please try again.");
      });
  };
  const closeButton = () => {
    setRegistraionCodeDisable(false);
    setFieldValue("registrationCode", "");
  };
  const onSubmitOfRegister = (e) => {
    let { values, isValid, handleSubmit } = props;
    e.preventDefault();
    handleSubmit();

    if (isValid) {
      let params = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values.email,
        registration_code: values.registrationCode.toUpperCase(),
        timezone: moment.tz.guess(),
      };
      setLoading(true);
      AuthenticationApiSerivce.checkEmailAndCode(params)
        .then((res) => {
          if (res.status) {
            setData(res?.data);
            let paramsForSignup = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              registrationCode: values.registrationCode.toUpperCase(),
            };
            localStorage.setItem("data", JSON.stringify(values));

            if (!res?.data?.access_token) {
              props.history.push("/insurance", {
                ...paramsForSignup,
                providerCode: !!res?.data?.is_provider_code,
                providerType: res?.data?.provider_type,
              });
              setLoading(false);
            } else {
              props?.login({
                accessToken: res?.data?.access_token,
                userId: res?.data?.user?.id,
                firstName: res?.data?.user?.first_name,
                lastName: res?.data?.user?.last_name,
                email: res?.data?.user?.email,
                isLogin: true,
                state: res?.data?.user?.state,
                insurance: res?.data?.user?.user_insurance.insurance,
              });
              setTncModal(true);
              setLoading(false);
            }
          } else {
            message.error(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          message.error("Something went wrong! Please try again.");
          setLoading(false);
        });
    }
  };
  return (
    <RegisterWrapper>
      <TncModal
        open={tncModal}
        toggle={() => setTncModal(!tncModal)}
        data={data?.user}
        {...props}
      />
      <RegistrationLayout>
        <div className="flex pt-1 justify-center">
          <div
            className={clsx(
              "flex flex-custom-1 justify-center ptb-8 align-start flex-y pa-12",
              isLg && "pa-32 pr-96"
            )}
          >
            <div className="d-flex justify-content-start pt-10">
              <h1 className="Glacial-Bold jumbotrons fs-44">
                {t("CREATE_ACCOUNT")}
              </h1>
            </div>
            <div className="fill-width">
              <Input
                lable={t("FIRST_NAME")}
                type="text"
                placeholder={t("FIRST_NAME")}
                id="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="text-capitalize fill-width"
                isFirstName={true}
              ></Input>
              <Error field="firstName" />
              <Input
                lable={t("LAST_NAME")}
                type="text"
                placeholder={t("LAST_NAME")}
                id="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="text-capitalize"
                isLastName={true}
              ></Input>
              <Error field="lastName" />
              <Input
                lable={t("EMAIL")}
                type="text"
                id="email"
                placeholder={t("EMAIL")}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              ></Input>
              <Error field="email" />
              <div className="position-relative">
                <Input
                  lable={t("REGISTRATION_CODE")}
                  type="text"
                  placeholder={t("REGISTRATION_CODE")}
                  isRegisterationCode={true}
                  id="registrationCode"
                  value={values.registrationCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={RegistraionCodeDisable}
                ></Input>
                {!RegistraionCodeDisable && (
                  <div className="flex flex-x code justify-content-center">
                    {!codeLoading ? (
                      <div
                        className={clsx(
                          "Glacial-Regular cursor-pointer borderB",
                          isTabletTilt && "fs-12",
                          !isLg && "fs-16"
                        )}
                        onClick={(e) => getCode()}
                      >
                        {t("DONT_HAVE_CODE")}
                      </div>
                    ) : (
                      <Loader height={36} width={36} />
                    )}
                  </div>
                )}
                {RegistraionCodeDisable && (
                  <div
                    onClick={(e) => {
                      closeButton();
                    }}
                    className="close Glacial-Regular cursor-pointer"
                  >
                    X
                  </div>
                )}
              </div>
              <Error field="registrationCode" />
              <Button
                text={t("REGISTER_BUTTON")}
                onClick={(e) => onSubmitOfRegister(e)}
                loading={Loading}
              ></Button>
              <div className="text-center mtp-15 ">
                <div className="Glacial-Regular mbp-2 fs-20">
                  {t("ALREADY_HAVE_ACCOUNT")}
                </div>
                <div
                  className={`${WIDTH} m-auto Glacial-Regular lightGreen--text mtp-1 font-weight-bold fs-20`}
                >
                  {t("DOWNLOAD_APP_INFO_LABEL")}
                </div>
              </div>
              <div className="app-icon mtp-6 pb-5 text-center">
                <img
                  className="cursor-pointer"
                  src={GooglePlayImage}
                  alt="Play store icon"
                  onClick={(e) => {
                    window.open(googleUrl);
                  }}
                ></img>
                <img
                  className="mlp-3 cursor-pointer"
                  src={AppStoreImage}
                  alt="App store icon"
                  onClick={(e) => {
                    window.open(appleUrl);
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </RegistrationLayout>
    </RegisterWrapper>
  );
};

export default compose(
  withRouter,
  enhancer,
  connect(null, { login })
)(Register);
