import styled from "styled-components";

const LandingPageWrapper = styled.div`
  margin: 0;
  .set-image {
    height: 100%;
    object-fit: cover;
    /* min-height: 100vh; */
    /* object-position: left; */
    @media (max-width: 1024px) {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    @media (min-width: 1025px) {
      min-height: 100vh;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* @media (min-width: 1500px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 15% !important;
    } */
  }
  .code {
    position: absolute;
    bottom: 12%;
    right: 0;
    border-bottom: 1px solid #5837d1;
    line-height: 1;
    color: #5837d1;
  }
  .jumbotrons {
    padding-top: 12%;
    color: #5A6B80;
    font-size: 3.5rem;
    font-weight: 700;
    // @media (max-width: 768px) {
    //   font-size: 2.3rem;
    // }
  }
  .app-icon {
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
      padding-bottom: 20%;
    }
  }
  .logo {
    top: 30px;
    left: 25%;
    @media (max-width: 1024px) {
      top: 15px;
      left: 40%;
    }
  }

  .box {
    width: 7rem;
    height: 7rem;
    background-color: #f5f5f5;
    border-radius: 7px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    font-size: 15px;
    @media (max-width: 1024px) {
      top: 15px;
      left: 40%;
    }
    @media (min-width: 1375px) and (max-width: 1500px) {
      font-size: 15px;
    }
    @media (min-width: 992px) and (max-width: 1375px) {
      width: 6rem;
      height: 6rem;
      font-size: 13px;
    }
    @media (min-width: 310px) and (max-width: 550px) {
      width: 5rem;
      height: 5rem;
      font-size: 11px;
    }
  }
  .box-image {
    height: 2rem;
    @media (min-width: 992px) and (max-width: 1500px) {
      height: 1.5rem;
    }
    @media (min-width: 310px) and (max-width: 550px) {
      width: 1.6rem;
      height: 1.6rem;
      font-size: 10px;
    }
  }
  .icon {
    top: -9px;
    right: 5px;
    @media (min-width: 1380px) and (max-width: 1440px) {
      top: -12px;
    }
    @media (min-width: 310px) and (max-width: 550px) {
      top: -1px;
    }
  }
  .icon-res {
    @media (min-width: 310px) and (max-width: 550px) {
      width: 12px;
      height: 12px;
    }
  }

  .popup {
    background-color: #58585a;
    width: 290px;
    height: 100px;
    bottom: 25px;
    right: -75px;
    border-radius: 10px;
  }
  .popup-place {
    width: 25%;
    height: 25%;
    background-color: white;
    border-radius: 50px;
    text-align: center;
    margin-right: 5px !important;
  }
  .text-danger {
    color: #e06226 !important;
  }
  .custom-datepicker {
    border-radius: 4px !important;
    font-size: 14px !important;
    border: 1px solid #ced4da !important;
    padding: 8px 10px;
    width: 100%;
    color: #495057;
    &:focus {
      border: 1px solid #9a9a9a !important;
      box-shadow: none !important;
      outline: 0;
    }
  }
  .custom-calender-class {
    .react-datepicker__triangle {
      border-bottom-color: rgba(0, 0, 0, 0.035);
    }
    .react-datepicker__header {
      background: rgba(0, 0, 0, 0.035);
      .react-datepicker-time__header {
        color: rgba(0, 0, 0, 0.87) !important;
      }
      .react-datepicker__current-month {
        color: rgba(0, 0, 0, 0.87) !important;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }
    }
    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day--selected {
          background: #495057;
          color: white;
        }
      }
    }
  }
`;

export default LandingPageWrapper;
