import React from "react";

export const WeekArrowIcon = (props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m20.447 11.105-16-8A1 1 0 0 0 3.152 4.53L7.82 12l-4.668 7.47a1 1 0 0 0 1.3 1.425l16-8a1 1 0 0 0 0-1.79zM6.731 17.517 9.554 13H12a1 1 0 0 0 0-2H9.554L6.731 6.483 17.764 12z"
        fill={props?.fill ? props?.fill : "#fff"}
      />
    </svg>
  );
};
