import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import axios from "axios";
import Encryption from "../api/Encryption";

const LANGUAGE = {
  "en-US": "en",
  en: "en",
  es: "es",
};

const backendOptions = {
  loadPath: (lng) => {
    const url = `${process.env.REACT_APP_BASE_URL}language/web-language-object`;
    return { url, lng };
  },
  allowMultiLoading: false,
  crossDomain: true,
  request: async (_options, url, _payload, callback) => {
    if (url) {
      const res = await axios.post(url.url, { language: LANGUAGE[url?.lng] });
      const data = Encryption.decrypt(res.data);
      const callbackData = { data: data?.data, status: 200 };
      callback(null, callbackData);
    }
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    // resources,
    language: "en",
    fallbackLng: "en", //language to use if translations in user language are not available
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // supportedLngs: ['en', 'ar','gu','hi'],
    backend: backendOptions,
  });

export default i18n;
